/* eslint-disable prefer-destructuring */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";

import MDBox from "components/MDBox";
import ELDownloadData from "components/ELDownloadData";
import ELDateLimits from "components/ELDateLimits";

import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
// import ApiError from "layouts/dashboard/components/RemoteApi/apiError";
// import PrepWMSReports from "./prepwmsReport";

import "./tableFormat.css";

function ShowTableData({ tableData }) {
  const options = {
    // title: "Report",
    // showRowNumber: true,
    allowHtml: true,
    // curveType: "function",
    // alternatingRowStyle: true,
    sortColumn: 0,

    cssClassNames: {
      headerRow: "elTableHeader",
      tableRow: "elTableEvenRow",
      oddTableRow: "elTableOddRow",
      tableCell: "elTableCell",
      selectedTableRow: "",
      hoverTableRow: "",
      // headerCell: "elTableHeaderCell",
      rowNumberCell: "",
    },
    pageSize: 10,
    width: "100%",
  };

  return <Chart chartType="Table" data={tableData} options={options} />;
}

function PopulateReports({ baseAPI }) {
  // console.log("ReportTable...1 dataAPI", baseAPI);

  if (!baseAPI) return null;

  const [dataApi, setPlotAPI] = useState("");

  const getReportDataAPI = (dateInfo) => {
    // https://rss-iiot.in/solarbe/v1/RSSA1024/WMS-1/wms/2024-09-11/2024-09-12/get_solar_data_tabular

    // const baseAPI = "https://rss-iiot.in/solarbe/v1/RSSA1024/WMS-1/wms/";

    const delimiter = "/";
    const apiEndPoint = "/get_solar_data_tabular";
    const reportDate = "";
    // const wmsReportDATAAPI = baseApi.concat(
    //   dateInfo.startDate,
    //   delimiter,
    //   dateInfo.endDate,
    //   apiEndPoint
    // );
    const wmsReportDATAAPI = reportDate.concat(
      dateInfo.startDate,
      delimiter,
      dateInfo.endDate,
      apiEndPoint
    );
    // console.log("wmsReportDATAAPI...", wmsReportDATAAPI);
    return wmsReportDATAAPI;
  };

  let refresh = false;

  const getDateLimits = (dateInfo) => {
    const temp = getReportDataAPI(dateInfo);
    refresh = !refresh;
    useEffect(() => {
      setPlotAPI(baseAPI.concat(temp));
    }, [refresh]);
  };

  const { data: tableData, loading, error } = useFetch(dataApi);

  if (error) {
    // console.log("ReportTable...3 error", error, dataApi);
    if (error.elmessage) return <MDBox color="error">{error.elmessage}</MDBox>;
    return <MDBox color="error">{error.message}</MDBox>;
  }

  if (loading) return <LoadingSpin pattern="graph" />;

  // if (tableData)
  //   if (tableData.length > 0) {
  //     // const { tableHeader, tableRowData } = flattenObject(tableData);
  //     return (
  //       <MDBox flex justifyContent="center" alignItems="center" mt={2}>
  //         <ELDownloadData rawData={tableData} />
  //         {ShowTableData({ tableData })}
  //       </MDBox>
  //     );
  //   }

  return (
    <MDBox flex>
      <MDBox display="flex" justifyContent="flex-end" alignItems="center" pr={2}>
        <ELDateLimits getDateLimits={getDateLimits} />
      </MDBox>
      <MDBox flex justifyContent="center" alignItems="center" mt={2}>
        {tableData && (
          <>
            <ELDownloadData rawData={tableData} />
            {ShowTableData({ tableData })}
          </>
        )}
      </MDBox>

      {/* {dataApi && <MDBox>{dataApi}</MDBox>} */}
    </MDBox>
  );

  // console.log("ReportTable...2 dataAPI", dataAPI);
  // const { data: tableData, loading, error } = useFetch(dataAPI);

  // if (error) {
  //   // console.log("ReportTable...3 error", error);
  //   if (error.elmessage) return <MDBox color="error">{error.elmessage}</MDBox>;
  //   return <MDBox color="error">{error.message}</MDBox>;
  // }

  // if (loading) return <LoadingSpin pattern="graph" />;

  // if (tableData)
  //   if (tableData.length > 0) {
  //     // const { tableHeader, tableRowData } = flattenObject(tableData);
  //     return (
  //       <MDBox flex justifyContent="center" alignItems="center" mt={2}>
  //         <ELDownloadData rawData={tableData} />
  //         {ShowTableData({ tableData })}
  //       </MDBox>
  //     );
  //   }
  // return null;
}

ShowTableData.defaultProps = {
  tableData: "",
};

ShowTableData.propTypes = {
  tableData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

PopulateReports.defaultProps = {
  baseAPI: "",
};

PopulateReports.propTypes = {
  baseAPI: PropTypes.string,
};

export default PopulateReports;
