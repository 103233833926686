global.currentPath = null;
// global.currentDev = null;
// global.fethDetails = false;
// global.timeStamp = null;
// global.DigitaltimeStamp = null;
// global.IsSetDefaultGraph = true;
// global.IsDownLoad = false;

// global.IsMockData = true;
// global.baseurl = "https://myiiot.in/backend485/home/";
// global.baseurl = "https://www.myiiot.in/extruderbe/v1/";

// global.baseurl = "https://myiiot.in/rnsbe/v1/";
global.dcCount = 0;
global.baseurl = "https://solarbe.rss-iiot.in/v1/";
global.newbaseurl = "https://rss-iiot.in/solarbe/v1/";

// global.baseurl = "https://rss-iiot.in/elzebe/v1/";

global.REACT_APP_API_URL = "https://api.openweathermap.org/data/2.5";
global.REACT_APP_API_KEY = "edfd7992f3a91ed08d834993e29bdb00";
global.REACT_APP_ICON_URL = "https://openweathermap.org/img/wn/";

// edfd7992f3a91ed08d834993e29bdb00
// 1c070776cf9988482bdd9e8bc1ad6ba3
