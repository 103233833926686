import { useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";

const handleSubmitNew = (row) => {
  // console.log("row Data : ", row.original);

  localStorage.setItem("devLocation", row.original.Location);
  localStorage.setItem("devSubStation", row.original.Area);
  localStorage.setItem("devName", row.original.DeviceName);
  localStorage.setItem("devID", row.original.DeviceID);
  localStorage.setItem("devStatus", row.original.Status);
  localStorage.setItem("devTimeStamp", row.original.LastActive);
  localStorage.setItem("devLatitude", row.original.Lat);
  localStorage.setItem("devLongitude", row.original.Long);

  localStorage.setItem("devClientInfo", row.original.Commisioned);
};

function DeviceTableList({ column, data }) {
  // console.log("DeviceTableList....column, data", column, data);
  const customerName = localStorage.getItem("customer");

  const isAdmin = localStorage.getItem("usrRole") === "admin";
  // const isAdmin = false;

  const tableData = data;

  const columns = useMemo(() => column, []);
  // console.log("MEMO Col", columns);

  // const handleSaveRow = async ({ exitEditingMode, row, values }) => {
  //   // if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
  //   tableData[row.index] = values;
  //   // send/receive api updates here
  //   setTableData([...tableData]);
  //   exitEditingMode(); // required to exit editing mode
  // };

  // return <MDTypography> {customerName} Work In Progress</MDTypography>;
  return (
    <MaterialReactTable
      enableDensityToggle={false}
      enableGlobalFilter
      enableColumnFilters={false}
      renderTopToolbarCustomActions={() => (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="left"
          bgColor="info"
          variant="gradient"
          borderRadius="section"
          px={2}
        >
          <MDTypography component="p" variant="button" color="white" display="flex" p={1}>
            <MDTypography component="span" variant="button" fontWeight="bold" color="white">
              {customerName} :
            </MDTypography>
            &nbsp; {localStorage.getItem("currentusr")}
          </MDTypography>
        </MDBox>
      )}
      initialState={{
        density: "compact",
        columnVisibility: { Lat: false, Long: false },
        pagination: { pageSize: 20, pageIndex: 0 },
      }}
      columns={columns}
      data={tableData}
      enableRowNumbers
      // rowNumberMode="original"
      // positionActionsColumn="last"
      displayColumnDefOptions={{
        "mrt-row-actions": {
          header: "Explore",
        },
      }}
      enableRowActions
      renderRowActions={({ row }) => (
        <MDBox justifyContent="center" alignItems="center" px={0.2}>
          <Link to="/dashboard">
            <MDButton
              variant="gradient"
              color="info"
              iconOnly
              circular
              size="small"
              onClick={() => handleSubmitNew(row)}
            >
              <Icon>dashboard</Icon>
            </MDButton>
          </Link>
          {isAdmin && (
            <Link to="/managedevice">
              <MDButton
                variant="gradient"
                color="warning"
                iconOnly
                circular
                size="small"
                onClick={() => handleSubmitNew(row)}
              >
                <Icon color="action">settings</Icon>
              </MDButton>
            </Link>
          )}
        </MDBox>
      )}
      muiTableBodyProps={{
        sx: {
          // stripe the rows, make odd rows a darker color
          "& tr:nth-of-type(even)": {
            backgroundColor: "#e0ffff",
          },
          "& tr:nth-of-type(odd)": {
            backgroundColor: "#f5f5dc",
          },
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          border: "1px solid rgba(81, 81, 81, 1)",
          fontSize: "12px",
          backgroundColor: "#4682b4",
          color: "#ffffff",
          align: "center",
          // variant: "gradient",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          border: "1px solid rgba(81, 81, 81, 1)",
          fontSize: "12px",
          fontWeight: "bold",
          textAlign: "center",
        },
      }}
    />
  );
}

DeviceTableList.defaultProps = {
  //   customerName: "",
  column: "",
  data: "",
  // onMobile:false,
};

DeviceTableList.propTypes = {
  //   customerName: PropTypes.string,
  // onMobile:PropTypes.bool,
  column: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default DeviceTableList;
