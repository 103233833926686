/* eslint-disable no-param-reassign */
import { useState } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// import { Chart } from "react-google-charts";
import Icon from "@mui/material/Icon";

function ELDefaultDaily({ getDateLimits, dateInfo }) {
  //   console.log("ELDateLimits...", dateInfo);

  moment.tz.setDefault("Asia/Calcutta");

  const [startDate, setStartDate] = useState(dateInfo.startDate);
  const [endDate, setEndDate] = useState(dateInfo.endDate);
  const [update, setUpdate] = useState(false);

  const setNewDates = () => {
    dateInfo.startDate = startDate;
    dateInfo.endDate = endDate;
    setUpdate(!update);
  };

  return (
    <MDBox px={2}>
      <Grid flex container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <MDInput
            // size="small"
            color="white"
            type="date"
            value={startDate}
            onInput={(e) => setStartDate(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 10,
                fontSize: 10,
                width: "90%",
                height: "2rem",
                fontWeight: "bold",
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <MDInput
            // size="small"
            color="white"
            type="date"
            value={endDate}
            onInput={(e) => setEndDate(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 10,
                fontSize: 10,
                width: "90%",
                height: "2rem",
                fontWeight: "bold",
              },
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <MDButton variant="gradient" color="info" circular iconOnly onClick={() => setNewDates()}>
            <Icon fontSize="small">refresh</Icon>
          </MDButton>
        </Grid>
      </Grid>
      {getDateLimits(dateInfo)}
    </MDBox>
  );
}

ELDefaultDaily.defaultProps = {
  dateInfo: {
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  },
  getDateLimits: "",
};

ELDefaultDaily.propTypes = {
  dateInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  getDateLimits: PropTypes.func,
};

export default ELDefaultDaily;
