import PropTypes from "prop-types";
import MDBox from "components/MDBox";

import {
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  // GridToolbarFilterButton,
  GridToolbarExport,
  // GridToolbarDensitySelector,
  // GridColumnGroupHeaderParams,
  // GridColumnGroupingModel,
} from "@mui/x-data-grid";

import { styled } from "@mui/material/styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end", p: 0 }}>
      <GridToolbarColumnsButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: "#49a3f1",
    color: "#ffffff",
    fontSize: 12,
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#e0ffff",
    color: "#000000",
    fontSize: 10,
    fontWeight: "bold",
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#f5f5dc",
    color: "#000000",
    fontSize: 10,
    fontWeight: "bold",
  },
}));

function ShowReportTable({ tableHeader, tableRowData }) {
  // const defaultSortInfo = [];
  // defaultSortInfo[tableHeader[0].field] = tableHeader[0].field;
  // defaultSortInfo.dir = -1;
  // defaultSortInfo.type = typeof tableHeader[0].field;

  // console.log("defaultSortInfo", defaultSortInfo);

  // if (tableHeader && tableRowData)
  return (
    <MDBox flex width="100%">
      <StripedDataGrid
        density="compact"
        rows={tableRowData}
        columns={tableHeader}
        // experimentalFeatures={{ columnGrouping: true }}
        // columnGroupingModel={columnGroupingModel}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
        slots={{
          toolbar: CustomToolbar,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50]}
        overflowX="scroll"
      />
    </MDBox>
  );
  // return null;
}

ShowReportTable.defaultProps = {
  tableHeader: "",
  tableRowData: "",
};

ShowReportTable.propTypes = {
  tableHeader: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  tableRowData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default ShowReportTable;
