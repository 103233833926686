/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function displayParam(val, index, bgcolor, txtColor, unit) {
  const myKey = "uni".concat(val, index);
  return (
    <Grid item key={myKey}>
      <MDBox
        flex
        align="center"
        key={val}
        borderRadius="25px"
        mt={0.25}
        bgColor={bgcolor}
        variant="gradient"
        shadow="lg"
      >
        <MDTypography
          flex
          variant="caption"
          color={txtColor}
          fontWeight="bold"
          textTransform="capitalize"
          alignItems="center"
        >
          {val}
        </MDTypography>
        {unit && (
          <MDTypography variant="button" color="text" fontSize="10px" fontWeight="bold" mt={0.3}>
            &nbsp;{unit}
          </MDTypography>
        )}
      </MDBox>
    </Grid>
  );
}

// function DefaultInfoCard({ color, icon, title, description, value, paramData }) {
function DefaultInfoCard({ title, paramData }) {
  let unit = "";
  switch (title) {
    case "Generation":
      unit = "kWh";
      break;
    case "Energy":
      unit = "kWh";
      break;
    default:
      unit = null;
  }

  return (
    <Card
      sx={{
        border: 4,
        bgcolor: "#edfbef",
        borderColor: "#adb5bd",
        borderRadius: "12px",
      }}
    >
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" px={3} py={1} mt={-2}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={6}>
            {Object.keys(paramData).map((key, index) => displayParam(key, index, "info", "white"))}
          </Grid>
          <Grid item xs={6}>
            {Object.values(paramData).map((val, index) =>
              displayParam(val, index, "white", "dark", unit)
            )}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  paramData: "",
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  paramData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default DefaultInfoCard;
