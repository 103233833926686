import PropTypes from "prop-types";
import moment from "moment-timezone";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";

import panel from "assets/images/solarEnergy/solar-panel.png";
import tower from "assets/images/solarEnergy/electric-tower.png";
import calendar from "assets/images/solarEnergy/calendar.png";
import bulb from "assets/images/solarEnergy/light-bulb.png";
import dgSet from "assets/images/solarEnergy/electric-generator.png";

import "./energy.css";

function Element({ circleColor, currValue, maxValue }) {
  const min = 0;
  const max = maxValue;

  const normalise = (value) => ((value - min) * 100) / (max - min);
  // eslint-disable-next-line
  // console.log("dbg", normalise(Number(currValue)));
  return (
    <CircularProgress
      variant="determinate"
      color={circleColor}
      value={maxValue > 0 ? normalise(Number(currValue)) : currValue}
      size="5.5rem"
      thickness={2.5}
    />
  );
}

function EnergyFlow({ powerData }) {
  // console.log("EnergyFlow powerData...", Object.keys(powerData));

  // ['Total Instantaneous Power', 'Captured_Time', 'Grid Instant Power', 'DG Instant Power', 'Inverter Instant Power']

  const powerValues = Object.values(powerData);

  // console.log("EnergyFlow...", Object.keys(powerData));
  // 'Stationcapacity', 'Total Instantaneous Power', 'Captured_Time', 'Grid Instant Power', 'DG Instant Power', 'Inverter Instant Power'

  // const powerValues = [1500, new Date(), 1500, 0, 1500];
  // const timeStamp = moment(new Date(localStorage.getItem("devTimeStamp"))).format(
  //   "DD-MMM-YY hh:mm a"
  //   );

  const timeStamp = moment(new Date(powerValues[2])).format(
    "DD-MMM-YY hh:mm a"
    // "DD-MMM-YY"
  );

  const myTest = (info) => (
    <MDBox>
      <MDBox textAlign="center">
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {info.elm} {info.value} {info.unit}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="center" alignItems="center">
        <MDBox sx={{ position: "relative", display: "inline-flex" }}>
          <Element circleColor={info.color} currValue={info.value} maxValue={info.maxValue} />
          <MDBox
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MDAvatar src={info.img} alt="solar" size="xl" variant="circular" />
          </MDBox>
        </MDBox>
        {Number(info.value) > 0 ? <div className={info.class} /> : <MDBox minHeight={10} />}
      </MDBox>
    </MDBox>
  );

  const info = [
    {
      elm: "Solar :",
      unit: "kW",
      color: "warning",
      value: powerValues[5],
      img: panel,
      class: "bubbleSolar",
      maxValue: powerValues[0],
    },
    {
      elm: timeStamp,
      unit: "",
      color: "light",
      value: "",
      img: calendar,
      class: "",
      maxValue: powerValues[0],
    },
    {
      elm: "Mains :",
      unit: "kW",
      color: "info",
      value: powerValues[3],
      img: tower,
      class: "bubbleGrid",
      maxValue: powerValues[0],
    },
    {
      elm: "DG :",
      unit: "kW",
      color: "secondary",
      value: powerValues[4],
      img: dgSet,
      class: "bubbleDG",
      maxValue: powerValues[0],
    },
  ];

  return (
    <Card sx={{ bgcolor: "#edfbef", maxHeight: "418px", pr: 1 }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" mb={3}>
        <MDTypography flex variant="h6" color="dark" fontWeight="bold" textTransform="capitalize">
          Power
        </MDTypography>
      </MDBox>
      <Grid flex container justifyContent="center" alignItems="center">
        <Grid item xs={6} ml={1}>
          <MDBox
            // borderLeft="8px solid #adb5bd"
            // borderTop="8px solid #edfbef"
            // borderRight="8px solid #edfbef"
            // borderBottom="8px solid #adb5bd"
            // border={8}
            // borderColor="#1A73E8"
            pb={5.5}
          >
            {myTest(info[0])}
          </MDBox>

          {/* <MDBox
            display="flex"
            borderLeft="8px solid #adb5bd"
            borderTop="8px solid #edfbef"
            // borderRight="8px solid #edfbef"
            // borderBottom="8px solid #adb5bd"
            justifyContent="center"
            alignItems="center"
            // borderRadius="12px"
            ml={0.5}
          >
            <MDBox pb={5} textAlign="center">
              <MDTypography variant="caption" color="text" fontWeight="medium">
                Solar : {powerValues[4]} kW
              </MDTypography>
              <MDBox>
                <MDAvatar src={panel} alt="solar" size="xl" variant="circular" />
              </MDBox>
              <MDBox mt={-10} ml={-2}>
                <Element
                  circleColor="warning"
                  currValue={powerValues[4]}
                  maxValue={powerValues[0]}
                />
              </MDBox>
              {Number(powerValues[4]) > 0 ? (
                <div className="bubbleSolar" />
              ) : (
                <MDBox minHeight={10} />
              )}
            </MDBox>
          </MDBox> */}
        </Grid>
        <Grid item xs={6} ml={-1}>
          <MDBox
            borderLeft="8px solid #adb5bd"
            // borderTop="8px solid #adb5bd"
            // borderRight="8px solid #edfbef"
            // borderBottom="8px solid #edfbef"
            // border={8}
            // borderColor="#1A73E8"
            pb={5.5}
          >
            {myTest(info[1])}
          </MDBox>
          {/* <MDBox
            display="flex"
            borderLeft="8px solid #adb5bd"
            borderTop="8px solid #adb5bd"
            borderRight="8px solid #edfbef"
            borderBottom="8px solid #edfbef"
            // border={8}
            // borderColor="#0000ff"
            justifyContent="center"
            alignItems="center"
            pb={5}
            ml={-1}
          >
            <MDBox textAlign="center">
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {timeStamp}
              </MDTypography>
              <MDBox ml={2}>
                <MDAvatar src={calendar} alt="solar" size="xl" variant="circular" />
              </MDBox>
              <MDBox mt={-10} ml={-0.8}>
                <Element circleColor="light" currValue={powerValues[3]} maxValue={powerValues[0]} />
              </MDBox>
            </MDBox>
          </MDBox> */}
        </Grid>
        <Grid item xs={6} ml={1} mt={-1}>
          <MDBox
            // borderLeft="8px solid #edfbef"
            borderTop="8px solid #adb5bd"
            // borderRight="8px solid #adb5bd"
            // borderBottom="8px solid #adb5bd"
            // border={8}
            // borderColor="#1A73E8"
            py={2.75}
          >
            {myTest(info[2])}
          </MDBox>
          {/* <MDBox
            display="flex"
            borderLeft="8px solid #edfbef"
            borderTop="8px solid #adb5bd"
            borderRight="8px solid #adb5bd"
            borderBottom="8px solid #adb5bd"
            // border={8}
            // borderColor="#00ff00"
            justifyContent="center"
            alignItems="center"
            mt={-1}
            ml={0.5}
          >
            <MDBox pt={5} textAlign="center">
              <MDTypography variant="caption" color="text" fontWeight="medium">
                Mains: {powerValues[2]} kW
              </MDTypography>
              <MDBox>
                <MDAvatar src={tower} alt="solar" size="xl" variant="circular" />
              </MDBox>
              <MDBox mt={-10} ml={-0.8}>
                <Element circleColor="info" currValue={powerValues[2]} maxValue={powerValues[0]} />
              </MDBox>

              {Number(powerValues[2]) > 0 ? (
                <div className="bubbleGrid" />
              ) : (
                <MDBox minHeight={10} />
              )}
            </MDBox>
          </MDBox> */}
        </Grid>
        <Grid item xs={6} ml={-1} mt={-1}>
          <MDBox
            // border={8}
            // borderColor="#1A73E8"
            py={2.75}
            borderLeft="8px solid #adb5bd"
            borderTop="8px solid #adb5bd"
            // borderRight="8px solid #adb5bd"
            // borderBottom="8px solid #edfbef"
          >
            {myTest(info[3])}
          </MDBox>
          {/* <MDBox
            // border={8}
            // borderColor="#ff0000"
            borderLeft="8px solid #adb5bd"
            borderTop="8px solid #adb5bd"
            borderRight="8px solid #adb5bd"
            borderBottom="8px solid #edfbef"
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={5}
            ml={-1}
            mt={-1}
          >
            <MDBox textAlign="center">
              <MDTypography variant="caption" color="text" fontWeight="medium">
                DG : {powerValues[3]} kW
              </MDTypography>
              <MDBox>
                <MDAvatar src={dgSet} alt="solar" size="xl" variant="circular" />
              </MDBox>
              <MDBox mt={-10} ml={-0.8}>
                <Element
                  circleColor="secondary"
                  currValue={powerValues[3]}
                  maxValue={powerValues[0]}
                />
              </MDBox>
              {Number(powerValues[3]) > 0 ? <div className="bubbleDG" /> : <MDBox minHeight={10} />}
            </MDBox>
          </MDBox> */}
        </Grid>
        <Grid item>
          <MDBox mt={-26} justifyContent="center" textAlign="center">
            {/* <MDTypography variant="caption" color="dark" fontWeight="medium">
              Load : {powerValues[0]} kW
            </MDTypography> */}
            <MDBox
              flex
              justifyContent="center"
              alignItems="center"
              border={4}
              // bgColor="#edfbef"
              bgColor={powerValues[1] > 0 ? "gold" : "grey"}
              borderColor="#adb5bd"
              borderRadius="12px"
              p={0.25}
              ml={1}
            >
              <MDAvatar src={bulb} alt="bulb" size="lg" variant="rounded" />
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox my={2} p={1} />
    </Card>
  );
}

Element.defaultProps = {
  // srcImg: null,
  circleColor: "#ffd700",
  currValue: 0,
  maxValue: 0,
  // Load: 0,
};

Element.propTypes = {
  // srcImg: PropTypes.string,
  circleColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Load: PropTypes.number,
};

EnergyFlow.defaultProps = {
  powerData: "",
};

EnergyFlow.propTypes = {
  powerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default EnergyFlow;
