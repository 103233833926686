import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";

const prettyLink = {
  fontSize: 12,
  fontWeight: "bold",
  color: "#1A73E8",
  // color: "#ffffff",
};

function ELDownloadData({ rawData }) {
  const fileName = "SOLAR_Report";
  return (
    <MDBox display="flex" justifyContent="flex-end" alignItems="center" mt={-1} pr={1} pb={1}>
      <CSVLink data={rawData} uFEFF={false} filename={fileName} style={prettyLink}>
        &#x21E9;&nbsp;EXPORT
      </CSVLink>
    </MDBox>
  );
}

ELDownloadData.defaultProps = {
  rawData: "",
};

ELDownloadData.propTypes = {
  rawData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default ELDownloadData;
