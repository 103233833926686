import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

const getSubBlock = (plantData, param) => {
  const subBlock = [];
  const PerformanceParam = ["CUF", "PR", "Irradiation"];

  Object.keys(plantData).forEach((el) => {
    if (param === "Performance") {
      if (PerformanceParam.includes(el)) {
        // console.log("Performance... el", el, plantData[el]);
        subBlock[el] = plantData[el];
      }
    }
    if (el.includes(param)) {
      // console.log("getSubBlock... el", el, plantData[0][el]);
      subBlock[el] = plantData[el];
    }
  });
  // console.log("getSubBlock...", genBlock);
  return { subBlock };
};

function TrailingGrid({ plantData }) {
  const { subBlock: GenerationInfo } = getSubBlock(plantData, "Generation");
  const { subBlock: EnergyInfo } = getSubBlock(plantData, "Energy");
  const { subBlock: PerformanceInfo } = getSubBlock(plantData, "Performance");

  return (
    <MDBox flex py={2}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={8} lg={4}>
          <DefaultInfoCard title="Generation" paramData={GenerationInfo} />
        </Grid>
        <Grid item xs={12} md={8} lg={4}>
          <DefaultInfoCard title="Energy" paramData={EnergyInfo} />
        </Grid>
        <Grid item xs={12} md={8} lg={4}>
          <DefaultInfoCard title="Performance" paramData={PerformanceInfo} />
        </Grid>
      </Grid>
    </MDBox>
  );
}

TrailingGrid.defaultProps = {
  plantData: null,
};

TrailingGrid.propTypes = {
  plantData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default TrailingGrid;
