const ELInputStyle = {
  control: (base) => ({
    ...base,
    height: 38,
    minHeight: 35,
    fontSize: 12,
    fontWeight: "bold",
    borderRadius: "25px",
    textAlign: "center",
    // backgroundColor: "#edfbef",
    // color: "#ffffff",
    // variant: "gradient",
  }),
  option: (styles) => ({
    ...styles,
    fontSize: 12,
    textAlign: "center",
    borderRadius: "25px",
  }),
};

export default ELInputStyle;
