/* eslint-disable prefer-destructuring */

import MDBox from "components/MDBox";

import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import ApiError from "layouts/dashboard/components/RemoteApi/apiError";

// import WeatherInfo from "layouts/dashboard/components/TitleInfo/weatherAPI";

import WmsCurve from "./wmsTrends";

const getLiveAPI = () => {
  const baseurl = global.baseurl;
  const delimiter = "/";
  const apiEndPoint = "/mbus_live";
  const filler = "/null";
  const usr = localStorage.getItem("currentusr");
  const location = localStorage.getItem("devLocation");
  const stationid = localStorage.getItem("devSubStation");
  const deviceID = localStorage.getItem("devID");

  const trendAPI = baseurl.concat(
    usr,
    delimiter,
    location,
    delimiter,
    stationid,
    delimiter,
    deviceID,
    filler,
    apiEndPoint
  );
  return trendAPI;
};

function PrepWMSGraph() {
  const WeatherParam = [];

  const liveAPI = getLiveAPI();

  // console.log("ParseLiveData...liveAPI", liveAPI);
  const { data: liveData, loading, error } = useFetch(liveAPI);

  if (loading) return <LoadingSpin />;
  if (error) return <ApiError errorDetail={error} />;

  if (liveData)
    if (liveData.length > 0) {
      const metaData = liveData[0].sources;
      metaData.forEach((element) => {
        Object.keys(element).forEach((key) => {
          if (String(key).includes("SENSOR-WMS-WDS")) {
            // console.log("WMS Param : ", element.data.length);
            for (let count = 0; count < element.data.length; count += 1) {
              WeatherParam.push({
                id: count,
                value: element.data[count].label,
                label: element.data[count].label,
              });
            }
          }
        });
      });
    }
  return (
    <>
      {WeatherParam.length && (
        <MDBox px={2} mt={1}>
          <WmsCurve data={WeatherParam} />
        </MDBox>
      )}
    </>
  );
}

// function WeatherReport() {
//   ELSecuity();
//   const onMobile = ELMobileView();
//   const currDevPath = localStorage.getItem("devLocation");
//   if (!currDevPath)
//     return (
//       <DashboardLayout>
//         {onMobile && <ELMobileNavbar />}
//         <MDBox display="flex" justifyContent="center" alignItems="center">
//           <MDTypography>Select Device From Device Tree</MDTypography>
//         </MDBox>
//       </DashboardLayout>
//     );

//   const liveAPI = getLiveAPI();

//   const WeatherData = [];

//   console.log("ParseLiveData...liveAPI", liveAPI);
//   const { data: liveData, loading, error } = useFetch(liveAPI);

//   if (liveData)
//     if (liveData.length > 0) {
//       console.log("liveData, loading, error", liveData[0].sources, loading, error);
//       const metaData = liveData[0].sources;
//       metaData.forEach((element) => {
//         Object.keys(element).forEach((key) => {
//           if (String(key).includes("SENSOR-WMS-WDS")) {
//             WeatherData.push(element.data);
//           }
//         });
//         // if (element.name === "WMS-SENSOR") {
//         // if (element.name === "SENSOR-WDS") {
//         //   WeatherData.push(element.data);
//         // }
//       });
//     }

//   return (
//     <DashboardLayout>
//       {onMobile && <ELMobileNavbar />}
//       <MDBox mb={onMobile && -2} />
//       <MDBox display="flex" justifyContent="center" py={1}>
//         {loading && (
//           <MDBox display="flex" bgColor="white" justifyContent="center" alignItems="center">
//             <LoadingSpin />
//           </MDBox>
//         )}
//         {error && <p>Server Error: {error.message}</p>}

//         {WeatherData.length > 0 ? (
//           <Grid
//             display="flex"
//             container
//             direction="row"
//             justifyContent="center"
//             alignItems="flex-start"
//             spacing={2}
//           >
//             {/* <WeatherInfo /> */}
//             <Grid item xs={4}>
//               {WeatherData.length > 0 && <WeatherReportCard data={WeatherData} />}
//             </Grid>
//             <Grid item xs={8}>
//               <MDBox border={4} borderColor="#ced4da" borderRadius="10px">
//                 {WeatherData.length > 0 && <WmsCurve data={WeatherData} />}
//               </MDBox>
//             </Grid>
//           </Grid>
//         ) : (
//           <MDBox>Waiting For Input From Physical Sensors</MDBox>
//         )}
//       </MDBox>
//     </DashboardLayout>
//   );
// }
export default PrepWMSGraph;
