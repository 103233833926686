import MDBadge from "components/MDBadge";
import MenuItem from "@mui/material/MenuItem";

const status = (value) => (
  <>
    {value ? (
      <MDBadge badgeContent="" color="success" indicator />
    ) : (
      <MDBadge badgeContent="" color="error" indicator />
    )}
  </>
);

// const MBDropDown = (elm) => {
//   const ddList = [
//     ["9600", "14400", "19200"],
//     ["7", "8"],
//     ["1", "1.5", "2"],
//     // ["None", "Even", "Odd"],
//     ["0", "1", "2"],
//   ];
//   let index = 0;
//   switch (elm) {
//     default:
//     case "Buadrate": {
//       index = 0;
//       break;
//     }
//     case "Databits": {
//       index = 1;
//       break;
//     }
//     case "Stopbits": {
//       index = 2;
//       break;
//     }
//     case "Parity": {
//       index = 3;
//       break;
//     }
//   }

//   return ddList[index];
// };

const addDropDown = (element, option) => {
  const columnHeader = {
    header: element.name,
    accessorKey: element.name,
    align: "center",
    size: 20,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    muiTableBodyCellEditTextFieldProps: {
      select: true,
      children: option.map((state) => (
        <MenuItem key={state} value={state}>
          {state}
        </MenuItem>
      )),
    },
  };
  return columnHeader;
};

const addTextEdit = (element) => {
  const columnHeader = {
    header: element.name,
    accessorKey: element.name,
    align: "center",
    size: 20,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  };
  return columnHeader;
};

const addReadOnlyEntry = (element) => {
  const columnHeader = {
    header: element.name,
    accessorKey: element.name,
    align: "center",
    size: 20,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    enableEditing: false,
    muiTableBodyCellEditTextFieldProps: {
      variant: "outlined",
      size: "small",
      sx: {
        borderRadius: "5px",
        backgroundColor: "#90ee90",
        fontWeight: "bold",
        fontSize: "14px",
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#000000",
        },
      },
    },
  };
  return columnHeader;
};

const CreateTableHeader = (subTable) => {
  const TableHeader = [];

  subTable.forEach((element) => {
    let isEditable = false;
    let dropDownOpt = [];

    if (element.access === "readwrite") isEditable = true;
    if (element.selection) dropDownOpt = element.selection;
    if (isEditable) {
      if (dropDownOpt.length > 0) {
        // console.log("subTableKeys", subTableKeys[index], dropDownOpt);
        TableHeader.push(addDropDown(element, dropDownOpt));
      } else {
        TableHeader.push(addTextEdit(element));
      }
    } else {
      if (element.name === "Linkstatus") {
        TableHeader.push({
          header: element.name,
          accessorKey: element.name,
          align: "center",
          size: 20,
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
          enableEditing: false,
          Cell: ({ cell }) => status(Number(cell.getValue())),
          muiTableBodyCellEditTextFieldProps: {
            variant: "outlined",
            size: "small",
            sx: {
              borderRadius: "5px",
              backgroundColor: "#90ee90",
              fontWeight: "bold",
              fontSize: "14px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            },
          },
        });
      }
      if (element.name !== "Linkstatus") {
        TableHeader.push(addReadOnlyEntry(element));
      }
    }
  });

  // console.log("TableHeader...", TableHeader);

  return TableHeader;
};

// const CreateTableHeader = (subTableKeys) => {
//   const TableHeader = [];

//   const MBusKey = ["Baudrate", "Databits", "Stopbits", "Parity"];

//   // name: 'Deviceid', access: 'readonly',

//   if (subTableKeys)
//     if (subTableKeys.length > 0) {
//       if (subTableKeys !== "NA")
//         subTableKeys.forEach((elm) => {
//           console.log("subTableKeys ", elm, typeof elm);
//         });

//       // subTableKeys.forEach((elKey) => {
//       //   // console.log("SUB KEY...", elKey);
//       //   if (MBusKey.includes(elKey)) {
//       //     // console.log("Add Drop-Down ", elKey);
//       //     const opt = MBDropDown(elKey);
//       //     TableHeader.push({
//       //       header: elKey,
//       //       accessorKey: elKey,
//       //       align: "center",
//       //       size: 20,
//       //       muiTableHeadCellProps: {
//       //         align: "center",
//       //       },
//       //       muiTableBodyCellProps: {
//       //         align: "center",
//       //       },
//       //       muiTableBodyCellEditTextFieldProps: {
//       //         select: true,
//       //         children: opt.map((state) => (
//       //           <MenuItem key={state} value={state}>
//       //             {state}
//       //           </MenuItem>
//       //         )),
//       //       },
//       //     });
//       //   } else if (elKey === "Linkstatus") {
//       //     TableHeader.push({
//       //       header: elKey,
//       //       accessorKey: elKey,
//       //       align: "center",
//       //       size: 20,
//       //       Cell: ({ cell }) => status(Number(cell.getValue())),
//       //       muiTableHeadCellProps: {
//       //         align: "center",
//       //       },
//       //       muiTableBodyCellProps: {
//       //         align: "center",
//       //       },
//       //     });
//       //   } else {
//       //     TableHeader.push({
//       //       header: elKey,
//       //       accessorKey: elKey,
//       //       align: "center",
//       //       size: 20,
//       //       muiTableHeadCellProps: {
//       //         align: "center",
//       //       },
//       //       muiTableBodyCellProps: {
//       //         align: "center",
//       //       },
//       //     });
//       //   }
//       // });
//     }

//   //   console.log("remoteTableHeader TableHeader,TableData", TableHeader, TableData);
//   // testObject.forEach((el) => {
//   //   Object.assign(TableData, el);
//   // });

//   return { TableHeader };
// };

export default CreateTableHeader;
