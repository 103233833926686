/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState } from "react";

import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import RouteToDashBoard from "layouts/authentication/sign-in-to-dashboard";

import Axios from "axios";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function Cover() {
  const [username, setusername] = useState("");
  const [oldpassword, setOldpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [resetstatus, setResetStatus] = useState(false);

  async function validate() {
    const url = global.baseurl;
    const delimiter = "/";
    const endPoint = "/manage_credentials";

    const credentialsApi = url.concat(
      username,
      delimiter,
      oldpassword,
      delimiter,
      newpassword,
      endPoint
    );
    try {
      const { data: response } = await Axios.get(credentialsApi);
      if (response[0].message === "OK") {
        setResetStatus(true);
        localStorage.setItem("authenticated", true);
      } else {
        setResetStatus(false);
      }
    } catch (err) {
      // Handle Error Here
      // eslint-disable-next-line
      console.error(err);
    }
    return resetstatus;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.clear();
    validate();
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Resetting Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onChange={(e) => setusername(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Old Password"
                fullWidth
                onChange={(e) => setOldpassword(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="New Password"
                fullWidth
                onChange={(e) => setNewpassword(e.target.value)}
              />
            </MDBox>
            {newpassword && newpassword !== oldpassword && newpassword.length <= 8 ? (
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                  Reset Password
                </MDButton>
              </MDBox>
            ) : (
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" disabled fullWidth>
                  Choose Diffrent Password
                </MDButton>
              </MDBox>
            )}
          </MDBox>
          {resetstatus === true && <RouteToDashBoard />}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
