/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts

// @mui icons
import Dashboard from "layouts/dashboard";
// import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import DeviceTree from "layouts/devicetree";
// import DeviceList from "layouts/devicelist";
// import DeviceDetails from "layouts/devicedetails";
import Icon from "@mui/material/Icon";
import Cover from "layouts/authentication/reset-password/cover";
import ManageDevice from "layouts/managedevice";
import Trends from "layouts/trends";
import TrendsMPPT from "layouts/trends_mppt";
import TrendsString from "layouts/trends_string";
import WeatherReport from "layouts/wms_system";
import Reports from "layouts/reports";

const routes = [
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in/*",
    component: <SignIn />,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "DeviceTree",
    key: "devicetree",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/devicetree/*",
    component: <DeviceTree />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Trends-General",
    key: "trends",
    icon: <Icon fontSize="small">query_stats</Icon>,
    route: "/trends",
    component: <Trends />,
  },
  {
    type: "collapse",
    name: "Trends-MPPT",
    key: "trends_mppt",
    icon: <Icon fontSize="small">leaderboard</Icon>,
    route: "/trends_mppt",
    component: <TrendsMPPT />,
  },
  {
    type: "collapse",
    name: "Trends-Strings",
    key: "trends_strings",
    icon: <Icon fontSize="small">water</Icon>,
    route: "/trends_string",
    component: <TrendsString />,
  },
  {
    type: "collapse",
    name: "Weather",
    key: "weather",
    icon: <Icon fontSize="small">wb_sunny</Icon>,
    route: "/wms_system",
    component: <WeatherReport />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/reports",
    component: <Reports />,
  },
  // {
  //   type: "hidden",
  //   name: "DeviceDetails",
  //   key: "devicedetails",
  //   icon: <Icon fontSize="small">electric_meter</Icon>,
  //   route: "/devicedetails",
  //   component: <DeviceDetails />,
  // },
  // {
  //   type: "collapse",
  //   name: "DeviceList",
  //   key: "devicelist",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/devicelist",
  //   component: <DeviceList />,
  // },
  {
    // type: "collapse",
    type: "hidden",
    name: "Configuration",
    key: "managedevice",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/managedevice",
    component: <ManageDevice />,
  },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-up",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "hidden",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/reset-password/cover",
    component: <Cover />,
  },
];

export default routes;
