import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Axios from "axios";

// import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDSnackbar from "components/MDSnackbar";

// import Icon from "@mui/material/Icon";
// import MDBox from "components/MDBox";
// import TimerWarning from "./warningTimer";

const getPostUrl = () => {
  const baseurl = "https://solarbe.rss-iiot.in/v1/";
  const delimiter = "/";
  const devID = localStorage.getItem("devID");
  const username = localStorage.getItem("currentusr");
  const endPoint = "/rsssolarv1/setboxconfigurations";

  const postURL = baseurl.concat(username, delimiter, "null", delimiter, devID, endPoint);
  // "https://solarbe.rss-iiot.in/api/v1/iiot@rss/null/MZ2RSS1000/rsssolarv1/setboxconfigurations";

  return postURL;
};

function UpdateConfigData({ confData, resetConfData, refreshManagepage }) {
  // console.log("UpdateConfigData ....confData", confData, confData.length);

  const [res, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [postData, setPostData] = useState(confData);

  const [show, setShow] = useState(false);

  // // eslint-disable-next-line
  // console.log("confStatusInfo", confStatusInfo);

  useEffect(() => {
    setPostData(confData);
  }, [confData]);

  async function validate() {
    // const url =
    //   "https://solarbe.rss-iiot.in/api/v1/iiot@rss/null/MZ2RSS1000/rsssolarv1/setboxconfigurations";

    const url = getPostUrl();
    // console.log("Post url....", url);

    try {
      setLoading(true);
      await Axios.post(url, postData).then((response) => {
        // eslint-disable-next-line
        // console.log("API NON-NULL RESPONSE", response.data[0].config);
        setResponse(response.data[0].config);
        setError("");
      });
    } catch (err) {
      // Handle Error Here
      // eslint-disable-next-line
      // console.log(err);
      setError(err);
      setResponse("");
      setLoading(false);
    }
    setLoading(false);
  }

  const toggleSnackbar = async () => {
    setShow(!show);
    if (postData)
      if (postData.length) {
        await validate();
        resetConfData();
        setPostData(null);
      }
  };

  const refreshPage = () => {
    setShow(!show);
    refreshManagepage(true);
  };

  return (
    <>
      <MDButton variant="gradient" color="info" onClick={toggleSnackbar}>
        Update Configuration
      </MDButton>
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Configurtion Status"
        content={res !== null ? res : error}
        dateTime={loading ? "..." : "  "}
        open={show}
        close={refreshPage}
      />
    </>
  );
  // return (
  //   <MDBox>
  //     {!error && !loading && !res && (
  //       <MDButton variant="gradient" color="info" onClick={postConfigData}>
  //         Update Configurataion&nbsp;
  //         <Icon>update</Icon>
  //       </MDButton>
  //     )}
  //     {error && (
  //       <MDAlert color="error" dismissible>
  //         &nbsp; Config Failed : {error.message}&nbsp;
  //       </MDAlert>
  //     )}
  //     {loading && (
  //       <MDAlert color="primary" dismissible>
  //         &nbsp; Config In Progress&nbsp;
  //       </MDAlert>
  //       // <MDButton variant="gradient" color="warning" disabled>
  //       //   Config In Progress&nbsp;
  //       //   <Icon>stop</Icon>
  //       // </MDButton>
  //     )}
  //     {res === "PASS" && (
  //       <>
  //         <TimerWarning duration={60} elMessage="check" />
  //         <MDButton variant="gradient" color="info" onClick={postConfigData}>
  //           Update Configurataion&nbsp;
  //         </MDButton>
  //       </>
  //     )}
  //     {res === "QUEUED" && (
  //       <MDAlert color="warning" dismissible>
  //         &nbsp; Last Update Request Pending&nbsp;
  //       </MDAlert>
  //       // <MDButton variant="gradient" color="dark" disabled>
  //       //   Last Update Request Pending&nbsp;
  //       // </MDButton>
  //     )}
  //     {res === "FAIL" && (
  //       <MDAlert color="error" dismissible>
  //         &nbsp; Last Update Request Failed&nbsp;
  //       </MDAlert>
  //     )}
  //   </MDBox>
  // );
}

UpdateConfigData.defaultProps = {
  confData: "",
  resetConfData: "",
  // confStatusInfo: "",
  refreshManagepage: "",
};

UpdateConfigData.propTypes = {
  confData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  resetConfData: PropTypes.func,
  // confStatusInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  refreshManagepage: PropTypes.func,
};

export default UpdateConfigData;
