import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";

import DeviceInfo from "./deviceInfo";
import WeatherInfo from "./weatherAPI";

// function WeatherInfo() {
//   const timeStamp = moment(Date()).format("DD-MMM-YYYY hh:mm a");

//   const WeatherData = {
//     Time: timeStamp,
//     Temp: 38,
//     Humidity: 90,
//   };

//   return (
//     <Grid flex container direction="row" justifyContent="center" alignItems="center" spacing={2}>
//       <Grid item xs={6}>
//         {Object.keys(WeatherData).map((key) => displayParam(key, "warning", "dark"))}
//       </Grid>
//       <Grid item xs={6}>
//         {Object.values(WeatherData).map((val) => displayParam(val, "info", "dark"))}
//       </Grid>
//     </Grid>
//   );
// }

function TitleInfo({ CurrDeviceInfo }) {
  return (
    <Card sx={{ bgcolor: "#edfbef" }}>
      <MDBox flex justifyContent="center" pb={1} borderRadius="10px">
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <MDTypography flex variant="h6" color="dark" fontWeight="bold" textTransform="capitalize">
            Device
          </MDTypography>
        </MDBox>
        <MDBox justifyContent="center" alignItems="center" p={2}>
          {CurrDeviceInfo && <DeviceInfo DevInfo={CurrDeviceInfo} />}
        </MDBox>
        <MDBox justifyContent="center" alignItems="center" px={2} mt={2.5}>
          {CurrDeviceInfo && <WeatherInfo />}
        </MDBox>
      </MDBox>
    </Card>
  );
}

TitleInfo.defaultProps = {
  CurrDeviceInfo: "",
};

TitleInfo.propTypes = {
  CurrDeviceInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
};

export default TitleInfo;
