/* eslint-disable no-param-reassign */
// import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";
import MDBox from "components/MDBox";
// import ELDownloadData from "components/ELDownloadData";
import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

const ParseWMSData = (graphData, selectedOption) => {
  if (!graphData) return null;

  const thisData = graphData;
  const Columns = [];
  const chartData = [];

  // console.log("ParseWMSData...", graphData, selectedOption);

  thisData.forEach((el, index) => {
    const rowEntry = [];
    Object.keys(el).forEach((key) => {
      if (key.includes(selectedOption) || key.includes("Captured_Time")) {
        if (index === 0) {
          Columns.push(key);
        } else {
          let thisRow = Number(el[key]);
          //  Captured_Time value with space
          if (el[key].includes(" ")) thisRow = el[key];
          rowEntry.push(thisRow);
        }
      }
    });
    if (index === 0) chartData.push(Columns);
    else chartData.push(rowEntry);
  });

  return chartData;
};

function PlotWMSGraph({ url, chartInfo, selectedOption }) {
  if (!url) return null;

  const { data: graphData, loading, error } = useFetch(url);

  if (loading) return <LoadingSpin pattern="graph" />;
  if (error) {
    // console.log("ReportTable...3 error", error);
    if (error.elmessage)
      return (
        <MDBox color="error" textAlign="center">
          {error.elmessage}
        </MDBox>
      );
    return (
      <MDBox color="error" textAlign="center">
        {error.message}
      </MDBox>
    );
  }

  if (graphData)
    if (graphData.length) {
      const chartData = ParseWMSData(graphData, selectedOption);
      // console.log("PlotWMSGraph...", chartInfo, chartData);
      chartInfo.options.vAxis.title = selectedOption;

      return (
        <Chart
          chartType={chartInfo.chartType}
          data={chartData}
          options={chartInfo.options}
          height={chartInfo.height}
          loader={<LoadingSpin pattern="graph" />}
        />
      );
    } else {
      return <p> No data for selected dates </p>;
    }
  return null;
}

PlotWMSGraph.defaultProps = {
  url: "",
  chartInfo: "",
  selectedOption: "",
};

PlotWMSGraph.propTypes = {
  url: PropTypes.string,
  chartInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedOption: PropTypes.string,
};

export default PlotWMSGraph;
