import { useState, useMemo } from "react";

import PropTypes from "prop-types";

import { MaterialReactTable } from "material-react-table";
// import SliceColumnHeader from "./sliceColumn";

function DrawTable({ heading, column, data, setConfData, isEditable }) {
  // console.log("DrawTable...column, data", heading, column, data, typeof data, data.length);

  const [tableData, setTableData] = useState(() => data);
  const columns = useMemo(() => column, []);

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    // if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
    tableData[row.index] = values;
    // send/receive api updates here
    setTableData([...tableData]);

    setConfData([tableData[row.index]]);

    exitEditingMode(); // required to exit editing mode
  };

  // EDIT TABLE FOR ADMIN
  if (isEditable && heading !== "DEVICE STATUS")
    return (
      <MaterialReactTable
        enableDensityToggle={false}
        initialState={{ density: "compact" }}
        columns={columns}
        data={tableData}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        editingMode="modal"
        enableEditing
        onEditingRowSave={handleSaveRow}
        muiTablePaperProps={{
          sx: {
            borderRadius: "12px",
            // border: "1px dashed #e0e0e0",
          },
        }}
        muiTableBodyProps={{
          sx: {
            // stripe the rows, make odd rows a darker color
            "& tr:nth-of-type(even)": {
              backgroundColor: "#e0ffff",
            },
            "& tr:nth-of-type(odd)": {
              backgroundColor: "#f5f5dc",
            },
            borderRadius: "10px",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            border: "1px solid rgba(81, 81, 81, 1)",
            fontSize: "12px",
            backgroundColor: "#4682b4",
            color: "#ffffff",
            "& .Mui-TableHeadCell-Content": {
              justifyContent: "center",
            },
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            border: "1px solid rgba(81, 81, 81, 1)",
            fontSize: "12px",
            fontWeight: "bold",
            textAlign: "center",
          },
        }}
      />
    );

  // VIEW ONLY for Normal User
  return (
    <MaterialReactTable
      enableDensityToggle={false}
      initialState={{ density: "compact" }}
      columns={columns}
      data={tableData}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      muiTablePaperProps={{
        sx: {
          borderRadius: "12px",
          // border: "1px dashed #e0e0e0",
        },
      }}
      muiTableBodyProps={{
        sx: {
          // stripe the rows, make odd rows a darker color
          "& tr:nth-of-type(even)": {
            backgroundColor: "#e0ffff",
          },
          "& tr:nth-of-type(odd)": {
            backgroundColor: "#f5f5dc",
          },
          borderRadius: "10px",
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          border: "1px solid rgba(81, 81, 81, 1)",
          fontSize: "12px",
          backgroundColor: "#4682b4",
          color: "#ffffff",
          "& .Mui-TableHeadCell-Content": {
            justifyContent: "center",
          },
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          border: "1px solid rgba(81, 81, 81, 1)",
          fontSize: "12px",
          fontWeight: "bold",
          textAlign: "center",
        },
      }}
    />
  );
}

DrawTable.defaultProps = {
  //   customerName: "",
  heading: "",
  column: "",
  data: "",
  setConfData: "",
  isEditable: false,
};

DrawTable.propTypes = {
  heading: PropTypes.string,
  column: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setConfData: PropTypes.func,
  isEditable: PropTypes.bool,
};

export default DrawTable;
