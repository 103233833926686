import { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

import ELDateLimits from "components/ELDateLimits";
import PlotTrend from "../plotTrend";

import GetPlotAPI from "../getPlotAPI";

function PlantGeneration() {
  const plotInfo = {
    actiontype: "planttotalgeneration",
    timetype: "daily",
  };

  const optionsInfo = {
    legend: { position: "none" },
    // legend: { alignment: "center", position: "top", textStyle: { color: "info", fontSize: 12 } },
    hAxis: {
      format: "MMM/d/yy",
      gridlines: {
        count: 7,
      },
      // textStyle: { fontSize: 10, bold: true },
    },
    vAxis: {
      title: "KWh",
      viewWindowMode: "explicit",
      viewWindow: {
        min: 0,
      },
    },
    seriesType: "bars",
    // series: { 5: { type: "line" } },
    // colors: ["#ef694d", "#dddb76", "#e84d00", "#388895", "#42403f", "#343a40"],
    pointSize: 4,
  };

  const chartInfo = {
    chartType: "ComboChart",
    height: "400px",
    options: optionsInfo,
  };

  const chartInfoFullScreen = {
    chartType: "ComboChart",
    height: window.innerHeight,
    width: "100%",
    options: optionsInfo,
  };

  let refresh = false;

  const [dataApi, setPlotAPI] = useState("");

  const getDateLimits = (dateInfo) => {
    const temp = GetPlotAPI(dateInfo, plotInfo);
    refresh = !refresh;
    useEffect(() => {
      setPlotAPI(temp);
    }, [refresh]);
  };

  // if (dataApi) console.log("dataApi...", dataApi);
  const handle = useFullScreenHandle();

  return (
    <FullScreen handle={handle}>
      <MDBox
        variant="gradient"
        // coloredShadow="info"
        borderRadius="10px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={0.5}
      >
        <Grid flex container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <MDBox textAlign="left" justifyContent="left" alignItems="center">
              <MDTypography variant="h6" color="black" fontSize="bold">
                Plant Generation (kWh)
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox display="flex" justifyContent="right" alignItems="center">
              <ELDateLimits getDateLimits={getDateLimits} />
              {handle.active ? (
                <MDButton variant="gradient" color="info" iconOnly onClick={handle.exit}>
                  <Icon fontSize="small">fullscreen_exit</Icon>
                </MDButton>
              ) : (
                <MDButton variant="gradient" color="info" iconOnly onClick={handle.enter}>
                  <Icon fontSize="small">fullscreen</Icon>
                </MDButton>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            {dataApi && (
              <MDBox
                flex
                width={handle.active ? window.innerWidth : "100%"}
                minHeight={handle.active && window.innerHeight}
                // p={0.5}
              >
                {handle.active ? (
                  <MDBox flex justifyContent="top" alignItems="center">
                    <MDTypography color="white">fs</MDTypography>
                    <PlotTrend url={dataApi} chartInfo={chartInfoFullScreen} />
                  </MDBox>
                ) : (
                  <PlotTrend url={dataApi} chartInfo={chartInfo} />
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </FullScreen>
  );
}

export default PlantGeneration;
