/* eslint-disable no-param-reassign */
// import { useEffect, useState } from "react";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";

// import breakpoints from "assets/theme/base/breakpoints";

import PropTypes from "prop-types";
import { Chart } from "react-google-charts";

import MDBox from "components/MDBox";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import ShowReportTable from "layouts/reports/showReportTable";
// import ELDownloadData from "components/ELDownloadData";
// import ParseExtendedData from "../trends/parseExtendedData";

function flattenObject(tableData) {
  const tableColumns = [{ field: "id", headerName: "ID" }];

  const tableRows = [];

  if (tableData)
    if (tableData.length) {
      const myHead = tableData[0];
      // console.log("myHead", myHead);

      myHead.forEach((elm) => {
        tableColumns.push({
          field: elm,
          headerName: elm,
          width: 150,
          align: "center",
          headerAlign: "center",
        });
      });

      // console.log("flattenObject...", tableData, myHead);

      tableData.forEach((elm, index) => {
        const rowEntry = {};
        rowEntry.id = index;
        elm.forEach((val, count) => {
          rowEntry[tableColumns[count + 1].headerName] = val;
        });
        if (index !== 0) tableRows.push(rowEntry);
      });
    }
  const tableHeader = tableColumns.slice(1, tableColumns.length);

  return { tableHeader, tableRows };
}

function PlotMPPT({ graphData }) {
  const series = [];

  // const chartData = ParseExtendedData(graphData, searchKey);

  const chartData = graphData;

  const optionsInfo = {
    hAxis: {
      format: "MMM/d/yy",
      gridlines: {
        count: 7,
      },
      textStyle: { fontSize: 10, bold: true },
    },
    vAxis: {
      title: "DC (amp)",
      min: 0,
    },
    legend: {
      alignment: "left",
      position: "top",
      textStyle: { color: "info", fontSize: 12 },
      maxLines: 4,
    },
    seriesType: "lines",
    // curveType: "function",
    width: "98%",
  };

  const chartInfo = {
    chartType: "ComboChart",
    height: "400px",
    options: optionsInfo,
  };

  const { tableHeader, tableRows } = flattenObject(chartData);

  // console.log("PlotMPPT ", chartData, chartInfo);

  const colours = [
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#66aa00",
    "#b82e2e",
    "#316395",
    "#994499",
    "#22aa99",
    "#aaaa11",
    "#6633cc",
    "#e67300",
    "#8b0707",
    "#651067",
    "#329262",
    "#5574a6",
    "#3b3eac",
    "#b77322",
    "#16d620",
    "#b91383",
    "#f4359e",
    "#9c5935",
    "#a9c413",
    "#2a778d",
    "#668d1c",
    "#bea413",
    "#0c5922",
    "#743411",
  ];

  for (let i = 1; i < chartData[0].length; i += 1) {
    series.push({ labelInLegend: chartData[0][i], color: colours[i - 1] });
  }

  chartInfo.options.series = series;
  // console.log("PlotTrendExtended...", chartInfo);

  const seriesDisplay = [];
  const seriesColorBackup = [];

  // console.log("series : ", series);
  if (series !== undefined)
    Object.keys(series).forEach((elm, index) => {
      seriesColorBackup[index] = elm.color;
      seriesDisplay[index] = true;
    });

  const columns = [];

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        const elChart = chartWrapper.getChart();
        const Selected = elChart.getSelection();
        const dataTable = chartWrapper.getDataTable();

        if (!columns.length)
          for (let i = 0; i < dataTable.getNumberOfColumns(); i += 1) {
            columns.push(i);
          }
        if (Selected.length > 0) {
          // Legend Selected
          if (Selected[0].row === null && Selected[0].column > 0) {
            const col = Selected[0].column;
            // toggle selected series display
            seriesDisplay[col] = !seriesDisplay[col];
            // select series display color
            series[col - 1].color = seriesDisplay[col] ? seriesColorBackup[col] : "#CCCCCC";
          }
        }
        const chartArea = elChart.getChartLayoutInterface().getChartAreaBoundingBox();
        const vAxisTop = elChart.getChartLayoutInterface().getVAxisValue(chartArea.top);
        const vAxisBtm = elChart
          .getChartLayoutInterface()
          .getVAxisValue(chartArea.top + chartArea.height);

        chartWrapper.setOption("series", series);
        chartWrapper.setOption("vAxis.viewWindow.max", vAxisTop);
        chartWrapper.setOption("vAxis.viewWindow.min", vAxisBtm);

        chartWrapper.draw();
      },
    },
  ];

  // return <p>MAN AT WORK</p>;
  return (
    <MDBox
      // variant="gradient"
      // coloredShadow="dark"
      // borderRadius="10px"
      // display="flex"
      justifyContent="center"
      alignItems="center"
      // textAlign="center"
      // p={0.5}
    >
      <Chart
        chartType={chartInfo.chartType}
        data={chartData}
        options={chartInfo.options}
        height={chartInfo.height}
        chartEvents={chartEvents}
        loader={<LoadingSpin pattern="graph" />}
      />
      <ShowReportTable tableHeader={tableHeader} tableRowData={tableRows} />
    </MDBox>
  );
}

// function PlotMPPT({ graphData }) {
//   const series = [];

//   // const chartData = ParseExtendedData(graphData, searchKey);

//   const chartData = graphData;

//   const optionsInfo = {
//     hAxis: {
//       format: "MMM/d/yy",
//       gridlines: {
//         count: 7,
//       },
//       textStyle: { fontSize: 10, bold: true },
//     },
//     vAxis: {
//       title: "DC (amp)",
//       min: 0,
//     },
//     legend: {
//       alignment: "left",
//       position: "top",
//       textStyle: { color: "info", fontSize: 12 },
//       maxLines: 4,
//     },
//     seriesType: "lines",
//     // curveType: "function",
//     width: "98%",
//   };

//   const chartInfo = {
//     chartType: "ComboChart",
//     height: "400px",
//     options: optionsInfo,
//   };

//   const [tabsOrientation, setTabsOrientation] = useState("horizontal");
//   const [tabValue, setTabValue] = useState(0);

//   const { tableHeader, tableRows } = flattenObject(chartData);

//   useEffect(() => {
//     // A function that sets the orientation state of the tabs.
//     function handleTabsOrientation() {
//       return window.innerWidth < breakpoints.values.sm
//         ? setTabsOrientation("vertical")
//         : setTabsOrientation("horizontal");
//     }

//     /**
//          The event listener that's calling the handleTabsOrientation function when resizing the window.
//         */
//     window.addEventListener("resize", handleTabsOrientation);

//     // Call the handleTabsOrientation function to set the state with the initial value.
//     handleTabsOrientation();

//     // Remove event listener on cleanup
//     return () => window.removeEventListener("resize", handleTabsOrientation);
//   }, [tabsOrientation]);

//   const handleSetTabValue = (event, newValue) => setTabValue(newValue);

//   // console.log("PlotMPPT ", chartData, chartInfo);

//   const colours = [
//     "#3366cc",
//     "#dc3912",
//     "#ff9900",
//     "#109618",
//     "#990099",
//     "#0099c6",
//     "#dd4477",
//     "#66aa00",
//     "#b82e2e",
//     "#316395",
//     "#994499",
//     "#22aa99",
//     "#aaaa11",
//     "#6633cc",
//     "#e67300",
//     "#8b0707",
//     "#651067",
//     "#329262",
//     "#5574a6",
//     "#3b3eac",
//     "#b77322",
//     "#16d620",
//     "#b91383",
//     "#f4359e",
//     "#9c5935",
//     "#a9c413",
//     "#2a778d",
//     "#668d1c",
//     "#bea413",
//     "#0c5922",
//     "#743411",
//   ];

//   for (let i = 1; i < chartData[0].length; i += 1) {
//     series.push({ labelInLegend: chartData[0][i], color: colours[i - 1] });
//   }

//   chartInfo.options.series = series;
//   // console.log("PlotTrendExtended...", chartInfo);

//   const seriesDisplay = [];
//   const seriesColorBackup = [];

//   // console.log("series : ", series);
//   if (series !== undefined)
//     Object.keys(series).forEach((elm, index) => {
//       seriesColorBackup[index] = elm.color;
//       seriesDisplay[index] = true;
//     });

//   const columns = [];

//   const chartEvents = [
//     {
//       eventName: "select",
//       callback({ chartWrapper }) {
//         const elChart = chartWrapper.getChart();
//         const Selected = elChart.getSelection();
//         const dataTable = chartWrapper.getDataTable();

//         if (!columns.length)
//           for (let i = 0; i < dataTable.getNumberOfColumns(); i += 1) {
//             columns.push(i);
//           }
//         if (Selected.length > 0) {
//           // Legend Selected
//           if (Selected[0].row === null && Selected[0].column > 0) {
//             const col = Selected[0].column;
//             // toggle selected series display
//             seriesDisplay[col] = !seriesDisplay[col];
//             // select series display color
//             series[col - 1].color = seriesDisplay[col] ? seriesColorBackup[col] : "#CCCCCC";
//           }
//         }
//         const chartArea = elChart.getChartLayoutInterface().getChartAreaBoundingBox();
//         const vAxisTop = elChart.getChartLayoutInterface().getVAxisValue(chartArea.top);
//         const vAxisBtm = elChart
//           .getChartLayoutInterface()
//           .getVAxisValue(chartArea.top + chartArea.height);

//         chartWrapper.setOption("series", series);
//         chartWrapper.setOption("vAxis.viewWindow.max", vAxisTop);
//         chartWrapper.setOption("vAxis.viewWindow.min", vAxisBtm);

//         chartWrapper.draw();
//       },
//     },
//   ];

//   // return <p>MAN AT WORK</p>;
//   return (
//     <MDBox
//       // variant="gradient"
//       // coloredShadow="dark"
//       // borderRadius="10px"
//       // display="flex"
//       justifyContent="center"
//       alignItems="center"
//       // textAlign="center"
//       // p={0.5}
//     >
//       <Tabs backgroudorientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
//         <Tab label="Trends" sx={{ borderBottom: 2 }} />
//         <Tab label="Table" sx={{ borderBottom: 2 }} />
//       </Tabs>
//       {tabValue === 0 && (
//         <>
//           <Chart
//             chartType={chartInfo.chartType}
//             data={chartData}
//             options={chartInfo.options}
//             height={chartInfo.height}
//             chartEvents={chartEvents}
//             loader={<LoadingSpin pattern="graph" />}
//           />
//           {/* {chartData && <ELDownloadData rawData={chartData} />} */}
//         </>
//       )}
//       {tabValue === 1 && <ShowReportTable tableHeader={tableHeader} tableRowData={tableRows} />}
//       {/* <Chart
//         chartType={chartInfo.chartType}
//         data={chartData}
//         options={chartInfo.options}
//         height={chartInfo.height}
//         chartEvents={chartEvents}
//         loader={<LoadingSpin pattern="graph" />}
//       />
//       {chartData && <ELDownloadData rawData={chartData} />} */}
//     </MDBox>
//   );
// }

PlotMPPT.defaultProps = {
  graphData: "",
};

PlotMPPT.propTypes = {
  graphData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default PlotMPPT;
