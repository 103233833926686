/* eslint-disable prefer-destructuring */

const ParseExtendedData = (graphData, searchKey, selectedOption) => {
  if (!graphData) return null;

  // console.log("ParseExtendedData", searchKey, selectedOption);

  const thisData = graphData;
  const mpptColumns = [];
  const chartData = [];
  const allowedKey = [
    ["Captured_Time", "MPPT Current"],
    ["Captured_Time", "MPPT Voltage"],
    // Dual Graph
    [
      "Captured_Time",
      "MPPT Current ".concat(selectedOption),
      "MPPT Voltage ".concat(selectedOption),
    ],
    ["Captured_Time", "Current String"],
  ];

  // console.log("thisData,graphData", thisData, graphData);

  thisData.forEach((el, index) => {
    const rowEntry = [];
    Object.keys(el).forEach((key) => {
      allowedKey[searchKey].forEach((keyparam) => {
        if (key.includes(keyparam)) {
          if (index === 0) {
            mpptColumns.push(key);
          } else {
            let thisRow = Number(el[key]);
            //  Captured_Time value with space
            if (el[key].includes(" ")) thisRow = el[key];
            rowEntry.push(thisRow);
          }
        }
      });
    });
    if (index === 0) chartData.push(mpptColumns);
    else chartData.push(rowEntry);
  });

  // console.log("ParseExtendedData...", chartData);

  return chartData;
};

export default ParseExtendedData;
