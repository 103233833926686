// import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// import Grid from "@mui/material/Grid";
// import moment from "moment-timezone";

function MeterInfo({ MeterData }) {
  const paramKey = Object.keys(MeterData);
  const paramValue = Object.values(MeterData);

  // localStorage.setItem("standardFq", paramValue[2]);

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2} height="2.5rem">
        <MDBox
          variant="gradient"
          bgColor="info"
          color="white"
          coloredShadow="info"
          borderRadius="50px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="2.5rem"
          height="2.5rem"
          mt={-2}
          ml={-2}
        >
          <Icon fontSize="small" color="inherit">
            electric_meter
          </Icon>
        </MDBox>
        <MDBox>
          <MDTypography variant="h6"> Parameters </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox justifyContent="center" pb={1} px={2}>
        <MDTypography component="p" variant="button" color="dark" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="text">
            {paramKey[0]} &Sigma;&nbsp; :
          </MDTypography>
          &nbsp;{paramValue[0]} kW
        </MDTypography>
      </MDBox>
      <MDBox pb={1} px={2}>
        <MDTypography component="p" variant="button" color="dark" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="text">
            {paramKey[1]} :
          </MDTypography>
          &nbsp;{paramValue[1]}&nbsp;
        </MDTypography>
      </MDBox>
      <MDBox pb={1} px={2}>
        <MDTypography component="p" variant="button" color="dark" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="text">
            {paramKey[2]} :
          </MDTypography>
          &nbsp;{paramValue[2]}&nbsp;Hz
        </MDTypography>
      </MDBox>
    </Card>
  );
}
MeterInfo.defaultProps = {
  MeterData: "",
};

// Typechecking props for the DetailList
MeterInfo.propTypes = {
  MeterData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default MeterInfo;
