import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// import Grid from "@mui/material/Grid";
import moment from "moment-timezone";
// import Icon from "@mui/material/Icon";
import Axios from "axios";

// function displayParam(val, bgcolor, txtColor) {
//   return (
//     <Grid item key={val}>
//       <MDBox
//         flex
//         align="center"
//         key={val}
//         borderRadius="25px"
//         mt={0.25}
//         bgcolor={bgcolor}
//         variant="gradient"
//         shadow="lg"
//       >
//         {val === "Temp" ? (
//           <MDTypography
//             flex
//             variant="caption"
//             color={txtColor}
//             fontWeight="bold"
//             textTransform="capitalize"
//           >
//             {val}&deg;C
//           </MDTypography>
//         ) : (
//           <MDTypography
//             flex
//             variant="caption"
//             color={txtColor}
//             fontWeight="bold"
//             textTransform="capitalize"
//           >
//             {val}
//           </MDTypography>
//         )}
//       </MDBox>
//     </Grid>
//   );
// }

function WeatherReportCard({ icon, data }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2} height="2.5rem">
        <MDBox
          variant="gradient"
          bgColor="info"
          color="white"
          coloredShadow="info"
          borderRadius="50px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="2.5rem"
          height="2.5rem"
          mt={-2}
          ml={-2}
        >
          <MDAvatar src={icon} alt="something here" size="md" />
        </MDBox>
        <MDBox>
          <MDTypography variant="h6">{data.place}</MDTypography>
          <MDTypography variant="button" color="dark" display="flex" fontWeight="bold">
            {data.Time}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox justifyContent="center" pb={1} px={2}>
        <MDTypography component="p" variant="button" color="dark" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="text">
            Temperature :
          </MDTypography>
          &nbsp;{data.temp}&nbsp;&deg;C
        </MDTypography>
      </MDBox>
      <MDBox pb={1} px={2}>
        <MDTypography component="p" variant="button" color="dark" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="text">
            Humidity :
          </MDTypography>
          &nbsp;{data.humidity}&nbsp;%
        </MDTypography>
      </MDBox>
      <MDBox pb={1} px={2}>
        <MDTypography component="p" variant="button" color="dark" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="text">
            Clouds :
          </MDTypography>
          &nbsp;{data.clouds}&nbsp;%
        </MDTypography>
      </MDBox>
    </Card>
  );
}

function WeatherInfo() {
  // const [lat, setLat] = useState([]);
  // const [long, setLong] = useState([]);
  const [weatherData, setWeatherData] = useState([]);

  const lat = localStorage.getItem("devLatitude");
  const long = localStorage.getItem("devLongitude");

  // console.log("WeatherInfo", lat, long);

  //   //    https://openweathermap.org/img/wn/10d@2x.png

  //   return (
  //     <WeatherReportCard icon={currIcon} data={WeatherData} />
  //   );

  useEffect(() => {
    const fetchData = async () => {
      // navigator.geolocation.getCurrentPosition((position) => {
      //   setLat(position.coords.latitude);
      //   setLong(position.coords.longitude);
      // });

      await Axios.get(
        `${global.REACT_APP_API_URL}/weather/?lat=${lat}&lon=${long}&units=metric&APPID=${global.REACT_APP_API_KEY}`
      )
        .then((response) => {
          // eslint-disable-next-line
          //   console.log("API NON-NULL RESPONSE", response);
          setWeatherData(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log("API ERR RESPONSE", err);
        });
    };
    fetchData();
  }, [lat, long]);

  if (weatherData)
    if (typeof weatherData.main !== "undefined") {
      //   console.log("weatherData...", weatherData, typeof weatherData);
      const timeStamp = moment(Date()).format("DD-MMM-YYYY hh:mm a");
      let currIcon = global.REACT_APP_ICON_URL;
      const imgTag = "@2x.png";
      currIcon = currIcon.concat(weatherData.weather[0].icon, imgTag);

      const WeatherData = {
        place: weatherData.name,
        Time: timeStamp,
        temp: weatherData.main.temp,
        humidity: weatherData.main.humidity,
        clouds: weatherData.clouds.all,
      };
      return <WeatherReportCard icon={currIcon} data={WeatherData} />;
    }
  return null;
}

WeatherReportCard.defaultProps = {
  icon: "",
  data: "",
};

WeatherReportCard.propTypes = {
  icon: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

// WeatherInfo.defaultProps = {
//   latitute: "",
//   longitude: "",
// };

// WeatherInfo.propTypes = {
//   latitute: PropTypes.number,
//   longitude: PropTypes.number,
// };

export default WeatherInfo;
