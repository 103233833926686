import { useState, useEffect } from "react";
import Select from "react-select";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ELMobileView from "components/ELMobileView";
import ELMobileNavbar from "components/ELMobileNavbar";
import ELInputStyle from "components/ELInputStyle";
// import ELDateLimits from "components/ELDateLimits";

import Grid from "@mui/material/Grid";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import PrepAPI from "./prepAPI";
import PrepData from "./prepData";

// import GetMPPTData from "./getMPPTData";
// import MPPTCurve from "./mpptCurve";
// import MPPTDualCurve from "./mpptCurveDual";

function TrendsMPPT() {
  const onMobile = ELMobileView();

  const currDevPath = localStorage.getItem("devLocation");
  if (!currDevPath)
    return (
      <DashboardLayout>
        {onMobile && <ELMobileNavbar />}
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <MDTypography>Select Device From Device Tree</MDTypography>
        </MDBox>
      </DashboardLayout>
    );

  // const [parseDates, setParseDate] = useState("");
  const [url, setURL] = useState("");
  const [option, setOption] = useState("");

  let refresh = false;

  const getApiInfo = (dataApi, selectedOption) => {
    // console.log("getApiInfo...", selectedOption, selectedParam);
    refresh = !refresh;
    useEffect(() => {
      setURL(dataApi);
      setOption(selectedOption);
    }, [refresh, dataApi]);
  };

  const optionsParam = [
    { id: 0, value: "Current", label: "Current" },
    { id: 1, value: "Voltage", label: "Voltage" },
  ];
  const [selectedParam, setSelectedParam] = useState(optionsParam[0]);

  return (
    <DashboardLayout>
      {onMobile && <ELMobileNavbar />}
      <MDBox display="flex" justifyContent="center" alignItems="center" mt={-1} px={2}>
        <Grid flex container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox border={4} bgColor="white" borderColor="#ced4da" borderRadius="10px">
              <MDBox textAlign="center" justifyContent="center" alignItems="center" pb={0.5}>
                <MDTypography variant="h6" color="black" fontSize="bold">
                  MPPT Inverters Statistics
                </MDTypography>
                <PrepAPI getApiInfo={getApiInfo} />
                <MDBox
                  // width="50%"
                  px="40%"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  mt={-4.5}
                >
                  <Select
                    defaultValue={selectedParam}
                    onChange={setSelectedParam}
                    options={optionsParam}
                    styles={ELInputStyle}
                  />
                </MDBox>
                <PrepData url={url} option={option} param={selectedParam.id} />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default TrendsMPPT;
