import PropTypes from "prop-types";
import { Chart } from "react-google-charts";

import MDBox from "components/MDBox";
import ELDownloadData from "components/ELDownloadData";

import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

import "./tableFormat.css";

function ShowTableData({ tableData }) {
  const options = {
    // title: "Report",
    // showRowNumber: true,
    allowHtml: true,
    // curveType: "function",
    // alternatingRowStyle: true,
    sortColumn: 0,

    cssClassNames: {
      headerRow: "elTableHeader",
      tableRow: "elTableEvenRow",
      oddTableRow: "elTableOddRow",
      tableCell: "elTableCell",
      selectedTableRow: "",
      hoverTableRow: "",
      // headerCell: "elTableHeaderCell",
      rowNumberCell: "",
    },

    pageSize: 12,
    // frozenColumns: 1,
    // height: "100%",
    // width: "100%",
  };
  // console.log("Header ", tableData[0]);
  // console.log("Header ", tableData[0].shift());
  // console.log("Header ", tableData[0]);

  return <Chart chartType="Table" data={tableData} options={options} />;
}

function PopulateReports({ dataAPI }) {
  // console.log("ReportTable...1 dataAPI", dataAPI);

  if (!dataAPI) return null;

  // console.log("ReportTable...2 dataAPI", dataAPI);
  const { data: tableData, loading, error } = useFetch(dataAPI);

  if (error) {
    // console.log("ReportTable...3 error", error);
    if (error.elmessage) return <MDBox color="error">{error.elmessage}</MDBox>;
    return <MDBox color="error">{error.message}</MDBox>;
  }

  if (loading) return <LoadingSpin pattern="graph" />;

  if (tableData)
    if (tableData.length > 0) {
      // const { tableHeader, tableRowData } = flattenObject(tableData);
      return (
        <MDBox flex>
          <ELDownloadData rawData={tableData} />
          {ShowTableData({ tableData })}
        </MDBox>
      );
    }
  return null;
}

ShowTableData.defaultProps = {
  tableData: "",
};

ShowTableData.propTypes = {
  tableData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

PopulateReports.defaultProps = {
  dataAPI: "",
};

PopulateReports.propTypes = {
  dataAPI: PropTypes.string,
};

export default PopulateReports;
