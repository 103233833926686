/* eslint-disable prefer-destructuring */

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import ELInputStyle from "components/ELInputStyle";
import ELDateLimits from "components/ELDateLimits";

import Select from "react-select";

import PlotWMSGraph from "./wmsPlotGraph";

// import PlotTrendExtended from "../trends/plotTrendExtended";

// import ELDateLimits from "../trends/getDateLimits";

// const customStyles = {
//   control: (base) => ({
//     ...base,
//     height: 35,
//     minHeight: 35,
//     fontSize: 12,
//     fontWeight: "bold",
//     borderRadius: "25px",
//     textAlign: "center",
//   }),
//   option: (styles) => ({
//     ...styles,
//     fontSize: 12,
//     textAlign: "center",
//     borderRadius: "25px",
//   }),
// };

function WmsCurve({ data }) {
  const optionsInfo = {
    // theme: "maximized",
    chartArea: {
      top: "5%",
      left: "8%",
      right: "5%",
      width: "90%",
      bottom: "15%",
      height: "90%",
      backgroundColor: "#f1f7f9",
    },

    hAxis: {
      format: "MMM/d/yy",
      gridlines: {
        count: 7,
      },
      textStyle: { fontSize: 10 },
      // showTextEvery: 5,
    },

    vAxis: {
      title: "Dummy",
      viewWindowMode: "explicit",
      viewWindow: {
        // max: 60,
        min: 0,
      },
    },
    legend: "none",
    // pointSize: 2,
    // hAxis: { title: "Time" },
    // legend: { alignment: "center", position: "top", textStyle: { color: "dark", fontSize: 12 } },
    seriesType: "lines",
    // colors: ["#e91e62", "#110e0e", "#00bbd4", "#4caf4f", "#ff9900"],
    curveType: "function",
  };

  const chartInfo = {
    chartType: "ComboChart",
    height: "500px",
    width: "95%",
    options: optionsInfo,
  };

  const [dataApi, setPlotAPI] = useState("");

  //   const Inverters = localStorage.getItem("INVNum");

  // const options = [];
  // data[0].forEach((element) => {
  //   if (element.label !== "Wind Direction")
  //     options.push({ value: element.label, label: element.label });
  // });

  // const [selectedOption, setSelectedOption] = useState(options[0]);
  const [selectedOption, setSelectedOption] = useState(data[0]);

  const getWMSSensorAPI = (dateInfo) => {
    const baseurl = global.baseurl;
    const delimiter = "/";
    const actiontype = "/regularmaindata";
    const apiEndPoint = "/get_mbus_data";
    const deviceID = localStorage.getItem("devID");
    // const sensor = "WMS-SENSOR";
    const sensor = "WMS-1";

    const trendAPI = baseurl.concat(
      deviceID,
      delimiter,
      sensor,
      actiontype,
      delimiter,
      dateInfo.startDate,
      delimiter,
      dateInfo.endDate,
      apiEndPoint
    );
    // console.log("getWMSSensorAPI : ", trendAPI);

    return trendAPI;
  };

  let refresh = false;

  const getDateLimits = (dateInfo) => {
    const temp = getWMSSensorAPI(dateInfo);
    refresh = !refresh;
    useEffect(() => {
      setPlotAPI(temp);
    }, [refresh]);
  };

  return (
    <MDBox display="flex" mt={0.5}>
      <Grid flex container justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <MDBox textAlign="center" justifyContent="center" alignItems="center" px={2}>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={data}
              styles={ELInputStyle}
            />
          </MDBox>
        </Grid>
        {/* <Grid item sx={6} width="40%" lineHeight={1.25}>
          <MDBox
            flex
            align="center"
            borderRadius="25px"
            bgColor="info"
            variant="gradient"
            shadow="lg"
            px={2}
            py={0.5}
            alignItems="center"
          >
            <MDTypography
              flex
              verticalAlign="center"
              variant="h6"
              color="white"
              fontWeight="regular"
            >
              Weather Statistics
            </MDTypography>
          </MDBox>
        </Grid> */}
        <Grid item xs={3} pr={1}>
          <ELDateLimits getDateLimits={getDateLimits} />
        </Grid>
        <Grid item xs={12}>
          {dataApi && (
            <PlotWMSGraph
              url={dataApi}
              chartInfo={chartInfo}
              selectedOption={selectedOption.label}
            />
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

WmsCurve.defaultProps = {
  data: "",
};

WmsCurve.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default WmsCurve;
