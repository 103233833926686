/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ELMobileView from "components/ELMobileView";
import ELMobileNavbar from "components/ELMobileNavbar";
import ELSecuity from "components/ELSecurity";

// import Divider from "@mui/material/Divider";

// import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Dashboard components
// import moment from "moment-timezone";

import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

import HeadingGrid from "./components/HeadingGrid";
import TrailingGrid from "./components/TrailingGrid";
import HeadingInfo from "./components/HeadingInfo";

function Dashboard() {
  const onMobile = ELMobileView();
  // console.log("onMobile...", onMobile);

  const [liveState, setliveState] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (liveState === 1) {
        setliveState(0);
        // console.log("livestatus ONLINE  :: ", liveState);
      }
      if (liveState === 0) {
        setliveState(1);
        // console.log("livestatus offline  :: ", liveState);
      }
    }, 600000);

    return () => clearTimeout(timer);
  }, [liveState]);
  ELSecuity();

  const currDevPath = localStorage.getItem("devLocation");
  const delimiter = "/";
  if (currDevPath) {
    const url = currDevPath.concat(
      delimiter,
      localStorage.getItem("devSubStation"),
      delimiter,
      localStorage.getItem("devID"),
      delimiter
    );

    // https://solarbe.rss-iiot.in/v1/userid/deviceid/plantdatalive
    // https://solarbe.rss-iiot.in/v1/tester/zzz/plantdatalive

    const urlBase = global.baseurl;

    const endPoint = "plantdatalive";
    const urlPlantLive = urlBase.concat(
      localStorage.getItem("currentusr"),
      delimiter,
      localStorage.getItem("devID"),
      delimiter,
      endPoint
    );

    // console.log("urlPlantLive", urlPlantLive);

    const { data: plantData, loading, error } = useFetch(urlPlantLive);

    if (plantData && !loading) {
      // console.log("plantData", plantData);

      localStorage.setItem("GridNum", plantData[0].Grids);
      localStorage.setItem("DGsNum", plantData[0].DGs);
      localStorage.setItem("INVNum", plantData[0].Inverters);

      return (
        <DashboardLayout>
          {/* {onMobile && <DashboardNavbar />} */}
          {onMobile && <ELMobileNavbar />}
          {plantData[0] && (
            <>
              <HeadingGrid plantData={plantData[0]} onMobile={onMobile} />
              <HeadingInfo CurrDeviceInfo={url} plantData={plantData[0]} />
              <TrailingGrid plantData={plantData[0]} />
            </>
          )}
        </DashboardLayout>
      );
    }
    if (loading)
      return (
        <DashboardLayout>
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <LoadingSpin />
          </MDBox>
        </DashboardLayout>
      );
    if (error)
      return (
        <DashboardLayout>
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <p>Server Error: {error.message}</p>
          </MDBox>
        </DashboardLayout>
      );
  }
  return (
    <DashboardLayout>
      {onMobile && <DashboardNavbar />}
      <MDBox display="flex" justifyContent="center" alignItems="center">
        <MDTypography>Select Device From Device Tree</MDTypography>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
