import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ELSecuity from "components/ELSecurity";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
// import RouteToDashBoard from "layouts/authentication/sign-in-to-dashboard";

import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

// Images
import bgImage from "assets/images/rss_bg.jpg";

import Axios from "axios";

// import ParseDeviceTree from "../devicetree";

const validateNew = async (username, password) => {
  const url = global.baseurl;
  const delimiter = "/";
  const endPoint = "credentials";
  let authentication = false;
  let errResponse = "Error : ";
  let loading = true;

  // console.log("validateNew username, password ", username, password);
  const credentialsApi = url.concat(username, delimiter, password, delimiter, endPoint);

  // console.log("credentialsApi ", credentialsApi);

  // const { data: response } = ;
  await Axios.get(credentialsApi)
    .then((response) => {
      if (response.data[0].message === "OK") {
        authentication = true;
        // console.log("response....", response);
        localStorage.setItem("currentusr", username);
        localStorage.setItem("usrRole", response.data[0].role);
        localStorage.setItem("customer", response.data[0].customer);
      } else {
        localStorage.setItem("currentusr", null);
        localStorage.setItem("usrRole", null);
        localStorage.setItem("customer", null);
      }
    })
    .catch((err) => {
      // console.log("...err...", err, typeof err);
      if (typeof err === "object") {
        if (err.code) errResponse = errResponse.concat(err.code);
        else errResponse = errResponse.concat(err.response.data.message);
        localStorage.setItem("currentusr", null);
        localStorage.setItem("usrRole", null);
        localStorage.setItem("customer", null);
      }
    })
    .finally(() => {
      loading = false;
    });

  return { authentication, errResponse, loading };
};

// const bypassAuth = async (username, password) => {
//   let authentication = false;
//   let errResponse = "Error : ";

//   authentication = true;
//   console.log("bypassAuth....", username, password);
//   localStorage.setItem("currentusr", username);
//   localStorage.setItem("usrRole", "admin");
//   localStorage.setItem("customer", "EL");

//   return { authentication, errResponse };
// };

function Basic() {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [authenticated, setauthenticated] = useState(false);
  const [errMsg, seterrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isReuest, setRequest] = useState(false);

  ELSecuity();

  const handleSubmitNew = async (uname, pwd) => {
    localStorage.clear();
    setRequest(true);
    const { authentication, errResponse, loading } = await validateNew(uname, pwd);

    // const { authentication, errResponse } = await bypassAuth(uname, pwd);
    setIsLoading(loading);
    setauthenticated(authentication);
    seterrorMsg(errResponse);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onChange={(e) => setusername(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={(e) => setpassword(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => handleSubmitNew(username, password)}
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
            {isLoading && isReuest && <LoadingSpin pattern="graph" />}
            {errMsg && (
              <MDTypography
                variant="h6"
                fontSize="bold"
                color="error"
                justifyContent="center"
                alignItems="center"
              >
                {errMsg}
              </MDTypography>
            )}
            {authenticated && (
              <Routes>
                <Route path="*" element={<Navigate to="/devicetree/*" />} />
              </Routes>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
