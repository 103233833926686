// import { useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import Icon from "@mui/material/Icon";

import ELMobileView from "components/ELMobileView";
import ELMobileNavbar from "components/ELMobileNavbar";
import ELSecuity from "components/ELSecurity";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import CUFVsYieldGrpah from "./curves/cufVsYield";
// import PeakVsGen from "./curves/peakVsGen";
import FrequencyGraph from "./curves/frequency";
import PRCurve from "./curves/prCurve";
import IrradationCurve from "./curves/irradation";
import PlantGeneration from "./curves/plantGeneration";
// import MPPTCurve from "./curves/mpptCurve";
import PlantCUF from "./curves/plantCUF";
import PlantTotalPower from "./curves/plantTotalPower";
import DeemedGeneration from "./curves/deemedGeneration";
// import MPPTDualCurve from "./curves/mpptCurveDual";
// import StringCurve from "./curves/stringCurve";

// 1.	Plant Total Power [done]
// 2.	Peak Vs KW
// 3.	Plant Generation [done]
// 4.	Plant PR [done]
// 5.	Plant CUF [done]
// 6.	Deemed Generation Curve [done]
// 7.	CUF/Specific Yields
// 8.	MPPT Curve (Voltage & Current):
// 9.	String Current Curve
// 10.	Irradiation Curve [done]
// 11.	Frequency [done]

// plantspecificyield ?? [TBD]

function Trends() {
  ELSecuity();
  const onMobile = ELMobileView();
  const currDevPath = localStorage.getItem("devLocation");

  // const [winWidth, setWinWidth] = useState(6);

  // const setViewPort = () => (winWidth === 6 ? setWinWidth(12) : setWinWidth(6));

  if (!currDevPath)
    return (
      <DashboardLayout>
        {onMobile && <ELMobileNavbar />}
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <MDTypography>Select Device From Device Tree</MDTypography>
        </MDBox>
      </DashboardLayout>
    );
  return (
    <DashboardLayout>
      {onMobile && <ELMobileNavbar />}
      <MDBox flex mt={-2}>
        <Grid flex container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox border={4} borderColor="#ced4da" borderRadius="10px">
              {/* 1.	Plant Total Power */}
              <PlantTotalPower />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
            <MDBox border={4} bgColor="white" borderColor="#ced4da" borderRadius="10px"> */}
          {/* 2.	Peak Vs KW */}
          {/* <PeakVsGen />
            </MDBox>
          </Grid> */}
          <Grid item xs={12} md={12} lg={12}>
            <MDBox border={4} bgColor="white" borderColor="#ced4da" borderRadius="10px">
              {/* 3.	Plant Generation */}
              <PlantGeneration />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox border={4} bgColor="white" borderColor="#ced4da" borderRadius="10px">
              {/* 4.	Plant PR */}
              <PRCurve />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox border={4} bgColor="white" borderColor="#ced4da" borderRadius="10px">
              {/* 5.	Plant CUF */}
              <PlantCUF />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox border={4} bgColor="white" borderColor="#ced4da" borderRadius="10px">
              <DeemedGeneration />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox border={4} bgColor="white" borderColor="#ced4da" borderRadius="10px">
              {/* 7.	CUF/Specific Yields */}
              <CUFVsYieldGrpah />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox border={4} bgColor="white" borderColor="#ced4da" borderRadius="10px">
              {/* 10.	Irradiation Curve */}
              <IrradationCurve />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox border={4} bgColor="white" borderColor="#ced4da" borderRadius="10px">
              {/* 11.	Frequency */}
              <FrequencyGraph />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Trends;
