// import PropTypes from "prop-types";
import moment from "moment-timezone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

// const elIcon = (color, icon) => (
//   <MDBox
//     variant="gradient"
//     bgColor={color}
//     color={color === "light" ? "dark" : "white"}
//     coloredShadow={color}
//     borderRadius="50px"
//     display="flex"
//     justifyContent="center"
//     alignItems="center"
//     width="2.5rem"
//     height="2.5rem"
//     mt={-2}
//     ml={-2}
//   >
//     <Icon fontSize="small" color="inherit">
//       {icon}
//     </Icon>
//   </MDBox>
// );

function ELMobileNavbar() {
  const timeStamp = moment(new Date()).format("DD-MMM-YY hh:mm a");
  return (
    // <Card sx={{ bgcolor: "#edfbef", mb: 2 }}>
    <MDBox
      display="flex"
      justifyContent="center"
      bgColor="#edfbef"
      // bgColor="warning"
      // variant="gradient"
      alignItems="center"
      borderRadius="25px"
      // border={0.5}
      // borderColor="#adb5bd"
      height="2rem"
      mb={2}
    >
      <Grid flex container justifyContent="space-between" alignItems="center">
        <Grid item>
          <MDBox flex mt={1.25} ml={-1}>
            <DashboardNavbar />
          </MDBox>
        </Grid>
        <Grid item>
          <MDBox flex justifyContent="center" alignItems="center" textAlign="center">
            <MDTypography
              flex
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              color="dark"
              // textTransform="capitalize"
              // variant="caption"
              fontWeight="bold"
            >
              {localStorage.getItem("currentusr")}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item>
          <MDBox flex alignItems="center" justifyContent="center" textAlign="center" pr={1} mt={-1}>
            <MDTypography variant="caption" fontSize="12px" fontWeight="bold" color="dark">
              {timeStamp}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
    // </Card>
  );
}

// ELMobileNavbar.defaultProps = {
//   timeStamp: "",
// };

// ELMobileNavbar.propTypes = {
//   timeStamp: PropTypes.string,
// };

export default ELMobileNavbar;
