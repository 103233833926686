import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

function ApiError({ errorDetail }) {
  if (errorDetail)
    return (
      <MDBox
        px={2}
        // width="max-content"
        mx="35%"
        justifyContent="center"
        alignItems="center"
        bgColor="red"
        align="center"
        borderRadius="10px"
      >
        {/* {errorDetail.message && (
          <MDTypography color="gold">ERR : {errorDetail.message}</MDTypography>
        )} */}
        {/* {errorDetail.id && <MDTypography color="gold">USR : {errorDetail.id}</MDTypography>} */}
        {/* {errorDetail.api && <MDTypography color="gold">API : {errorDetail.api}</MDTypography>} */}
        <MDBox>
          {errorDetail.elmessage ? (
            <MDTypography color="gold">Error : {errorDetail.elmessage}</MDTypography>
          ) : (
            <MDTypography color="gold">ERR : {errorDetail.message}</MDTypography>
          )}
        </MDBox>
      </MDBox>
    );
  return null;
}

ApiError.defaultProps = {
  errorDetail: [{}],
};

ApiError.propTypes = {
  errorDetail: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default ApiError;
