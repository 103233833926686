import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ELMobileView from "components/ELMobileView";
import ELMobileNavbar from "components/ELMobileNavbar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import StringCurve from "./stringCurve";

function TrendsString() {
  const onMobile = ELMobileView();

  const currDevPath = localStorage.getItem("devLocation");
  if (!currDevPath)
    return (
      <DashboardLayout>
        {onMobile && <ELMobileNavbar />}
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <MDTypography>Select Device From Device Tree</MDTypography>
        </MDBox>
      </DashboardLayout>
    );
  return (
    <DashboardLayout>
      {onMobile && <ELMobileNavbar />}
      <MDBox justifyContent="center" alignItems="center" width="100%" mt={-1}>
        <MDBox
          border={4}
          justifyContent="center"
          alignItems="center"
          bgColor="white"
          borderColor="#ced4da"
          borderRadius="10px"
          width="100%"
        >
          <StringCurve />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default TrendsString;
