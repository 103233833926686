/* eslint-disable prefer-destructuring */

const getMPPTAPI = (dateInfo, selectedOption) => {
  const baseurl = global.baseurl;
  const delimiter = "/";
  const actiontype = "/regularmaindata";
  const apiEndPoint = "/get_mbus_data";
  const deviceID = localStorage.getItem("devID");

  const trendAPI = baseurl.concat(
    deviceID,
    delimiter,
    selectedOption,
    actiontype,
    delimiter,
    dateInfo.startDate,
    delimiter,
    dateInfo.endDate,
    apiEndPoint
  );
  return trendAPI;
};

export default getMPPTAPI;
