/* eslint-disable prefer-destructuring */
import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import { Chart } from "react-google-charts";
import Select from "react-select";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ELInputStyle from "components/ELInputStyle";
// import ELDownloadData from "components/ELDownloadData";

import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import ParseExtendedData from "../trends/parseExtendedData";

function PlotMPPTDual({ graphData, invNum, dcCount }) {
  // if (!graphData) return null;

  // console.log("PlotMPPTDual...dcCount, invNum", invNum, dcCount);

  // if (!graphData || !dcCount) {
  //   return null;
  // }
  // const [chartData, setChartData] = useState("");

  let chartData = null;
  const options = [];
  for (let index = 1; index <= dcCount; index += 1) {
    const element = "MPPT-".concat(index);
    options.push({ value: index, label: element });
  }
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const optionsInfo = {
    hAxis: {
      format: "MMM/d/yy",
      gridlines: {
        count: 7,
      },
      textStyle: { fontSize: 10, bold: true },
    },
    vAxes: {
      0: {
        title: "Current",
        viewWindowMode: "explicit",
        viewWindow: {
          min: 0,
        },
      },
      1: {
        gridlines: { color: "transparent" },
        title: "Voltage",
      },
    },
    series: {
      0: { targetAxisIndex: 0, labelInLegend: "dc-current" },
      1: {
        targetAxisIndex: 1,
        type: "bars",
        labelInLegend: "dc-voltage",
      },
    },
    legend: {
      alignment: "center",
      position: "top",
      textStyle: { fontSize: 12, bold: true },
    },
  };

  const chartInfo = {
    chartType: "ComboChart",
    height: "400px",
    options: optionsInfo,
  };

  if (selectedOption) {
    chartData = ParseExtendedData(graphData, 2, selectedOption.value);
  }

  return (
    <MDBox
      // variant="gradient"
      // coloredShadow="dark"
      // borderRadius="10px"
      // display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      // p={0.5}
    >
      <Grid flex container direction="row" justifyContent="center" alignItems="center">
        <Grid item>
          <MDTypography variant="h6" color="black" fontSize="bold">
            MPPT DC Current Vs Voltage , INV-{invNum} &nbsp;
          </MDTypography>
        </Grid>
        <Grid item>
          <MDBox textAlign="center" justifyContent="center" alignItems="center" mx={2}>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              styles={ELInputStyle}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox width="100%">
            {chartData && (
              <Chart
                chartType={chartInfo.chartType}
                data={chartData}
                options={chartInfo.options}
                height={chartInfo.height}
                loader={<LoadingSpin pattern="graph" />}
              />
            )}
            {/* {chartData && <ELDownloadData rawData={chartData} />} */}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

PlotMPPTDual.defaultProps = {
  graphData: "",
  invNum: 0,
  dcCount: 0,
};

PlotMPPTDual.propTypes = {
  graphData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  invNum: PropTypes.number,
  dcCount: PropTypes.number,
};

export default PlotMPPTDual;
