/* eslint-disable no-param-reassign */
// import { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Grid from "@mui/material/Grid";

import ELMobileView from "components/ELMobileView";
import ELMobileNavbar from "components/ELMobileNavbar";
import ELSecuity from "components/ELSecurity";

// import { Divider } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import CreateTableHeader from "./CreateTableHeader";
import DrawTable from "./drawTable";
import UpdateConfigData from "./UpdateConfigData";
import DiscardPendingRequest from "./DiscardPendingRequest";

const segregteTable = (devConfigData) => {
  const subTableHead = [];
  // const subTableKeys = [];
  const subTables = [];
  const subTablesData = [];

  devConfigData.forEach((head) => {
    Object.keys(head).forEach((subHead) => {
      subTableHead.push(subHead);
      subTablesData.push(head[subHead]);
      if (Array.isArray(head[subHead][0])) {
        subTables.push(head[subHead][0]);
      } else {
        subTables.push(head[subHead]);
      }
    });
  });

  // for (let i = 0; i < subTableHead.length; i++) subTableKeys.push([]);

  // subTables.forEach((elm, id) => {
  //   // console.log("subTables  ", id, subTableHead[id], Array.isArray(elm[0]));
  //   let temp = elm;
  //   if (Array.isArray(elm[0])) {
  //     temp = elm[0];
  //   }
  //   temp.forEach((entry) => {
  //     subTableKeys[id].push(entry.name);
  //   });
  // });

  const flatTableData = [{}];
  subTablesData.forEach((entry) => {
    const tempData = {};
    if (Array.isArray(entry[0])) {
      const multiEntry = [];
      for (let i = 0; i < entry.length; i += 1) {
        const tempDataMulti = {};
        entry[i].forEach((elm) => {
          Object.assign(tempDataMulti, { [elm.name]: elm.value });
        });
        multiEntry.push(tempDataMulti);
        // console.log("multiEntry...", multiEntry);
      }
      flatTableData.push(multiEntry);
    } else {
      const singelEntry = [];
      entry.forEach((elm) => {
        Object.assign(tempData, { [elm.name]: elm.value });
      });
      singelEntry.push(tempData);
      flatTableData.push(singelEntry);
    }
  });

  // console.log("flatTbleData...", flatTableData, typeof flatTableData, flatTableData.length);

  const TableDataSlice = flatTableData.slice(1, flatTableData.length);

  // console.log("TableDataSlice...", TableDataSlice, TableDataSlice.length);

  return { subTableHead, subTables, subTablesData, TableDataSlice };
};

const getEditState = (devStatus) => {
  // editState === 0 => Not in Sync , Offline
  // editState === 1 => Not in Sync , Online
  // editState === 2 => Sync , Offline
  // editState === 3 => Sync , Online

  let editState = 0;
  let isSync = false;
  let isOnline = false;
  const isAdmin = localStorage.getItem("usrRole") === "admin";

  // console.log("devStatus...", devStatus);

  if (isAdmin) {
    devStatus.forEach((elm) => {
      if (elm.name === "Configuration" && elm.value === "In Sync") isSync = true;
      if (elm.name === "Linkstatus" && elm.value === "1") isOnline = true;
    });
  }
  if (!isSync && isOnline) editState = 1;
  else if (isSync && !isOnline) editState = 2;
  else if (isSync && isOnline) editState = 3;

  return editState;
};

const setConfStatusInfo = (editState) => {
  const confStatusInfo = {
    text: "Update Configuration",
    color: "info",
    edit: true,
  };

  switch (editState) {
    // editState === 0 => Not in Sync , Offline
    case 0:
      confStatusInfo.text = "Last Request Pending";
      confStatusInfo.color = "warning";
      confStatusInfo.edit = false;
      break;
    // editState === 1 => Not in Sync , Online
    case 1:
      confStatusInfo.text = "Last Request Pending";
      confStatusInfo.color = "warning";
      confStatusInfo.edit = false;
      break;
    // editState === 2 => Sync , Offline
    case 2:
      confStatusInfo.text = "Offline Mode Configuration";
      confStatusInfo.color = "secondary";
      confStatusInfo.edit = true;
      break;
    // editState === 3 => Sync , Online
    default:
    case 3:
      confStatusInfo.text = "Update Configuration";
      confStatusInfo.color = "info";
      confStatusInfo.edit = true;
      break;
  }
  return confStatusInfo;
};

function ManageDevice() {
  ELSecuity();
  const onMobile = ELMobileView();

  const currDevPath = localStorage.getItem("devLocation");
  if (!currDevPath)
    return (
      <DashboardLayout>
        {onMobile && <ELMobileNavbar />}
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <MDTypography>Select Device From Device Tree</MDTypography>
        </MDBox>
      </DashboardLayout>
    );

  const refreshManagepage = () => {
    window.location.reload(false);
  };

  const confData = [];
  const setConfData = (data) => {
    // console.log("SET EDIT DT");
    confData.push(data);
  };

  const resetConfData = () => {
    if (confData.length) {
      confData.splice(0, confData.length);
    }
  };

  const deviceTable = [];
  const url = global.baseurl;
  const delimiter = "/";
  const filler = "null";
  // const endPoint = "rsssolarzev1/getboxconfigurations";
  const endPoint = "rsssolarzev2/getboxconfigurations";

  // solarbe.rss-iiot.in/v1/iiot@rss/null/MZ2RSS1000/rsssolarzev2/getboxconfigurations

  const usr = localStorage.getItem("currentusr");
  const deviceID = localStorage.getItem("devID");

  // console.log("DeviceTree...url usr", url, usr);

  const configApi = url.concat(usr, delimiter, filler, delimiter, deviceID, delimiter, endPoint);

  // console.log("configApi...", configApi);

  const { data: devConfigData, loading, error } = useFetch(configApi);
  if (devConfigData && !loading)
    if (devConfigData.length > 0) {
      // console.log("devConfigData...", devConfigData);

      const { subTableHead, subTables, subTablesData, TableDataSlice } =
        segregteTable(devConfigData);

      subTables.forEach((subTable) => {
        const TableHeader = CreateTableHeader(subTable);
        deviceTable.push(TableHeader);
      });

      const editState = getEditState(subTables[0]);

      // console.log("editState", editState);

      const confStatusInfo = setConfStatusInfo(editState);

      // console.log("editState", confStatusInfo);

      return (
        <DashboardLayout>
          {onMobile && <ELMobileNavbar />}
          <MDBox
            display="flex"
            mt={-1}
            p={2}
            border={4}
            bgColor="grey-300"
            borderColor="#adb5bd"
            borderRadius="12px"
          >
            <Grid
              flex
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={1}
            >
              {deviceTable.map(
                (column, index) =>
                  subTablesData[index].length > 0 && (
                    <Grid key={subTableHead[index]} item xs={12}>
                      <MDBox flex justifyContent="center" alignItems="center">
                        <MDBox
                          justifyContent="center"
                          alignItems="center"
                          bgColor="info"
                          variant="gradient"
                          m={1}
                          p={1}
                          width={onMobile ? "50%" : "15%"}
                          textAlign="center"
                          borderRadius="50px"
                          display="flex"
                        >
                          <MDTypography variant="button" fontWeight="bold" color="white">
                            {subTableHead[index]}
                          </MDTypography>
                        </MDBox>
                        <DrawTable
                          heading={subTableHead[index]}
                          column={column}
                          data={TableDataSlice[index]}
                          setConfData={setConfData}
                          isEditable={confStatusInfo.edit}
                        />
                        {subTableHead[index] === "DEVICE STATUS" && editState !== 3 && (
                          <MDBox
                            justifyContent="center"
                            bgColor={confStatusInfo.color}
                            variant="gradient"
                            textAlign="center"
                            borderRadius="12px"
                            border={1}
                            borderColor="dark"
                            mx="33%"
                            mt={1}
                            width="33%"
                            flex
                          >
                            {confStatusInfo.text}&nbsp;
                          </MDBox>
                        )}
                      </MDBox>
                    </Grid>
                  )
              )}
              <Grid item xs={6} md={6} lg={6}>
                <MDBox py={1} display="flex" justifyContent="center" alignItems="center">
                  {confStatusInfo.edit ? (
                    <UpdateConfigData
                      confData={confData}
                      resetConfData={resetConfData}
                      confStatusInfo={confStatusInfo}
                      refreshManagepage={refreshManagepage}
                    />
                  ) : (
                    <DiscardPendingRequest
                      confStatusInfo={confStatusInfo}
                      refreshManagepage={refreshManagepage}
                    />
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </DashboardLayout>
      );
    }
  return (
    <DashboardLayout>
      {loading && <LoadingSpin />}
      {error && <p>Server Error: {error.message}</p>}
    </DashboardLayout>
  );
}

export default ManageDevice;
