/* eslint-disable prefer-destructuring */

import { useEffect, useState } from "react";

import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";

// import Menu from "@mui/material/Menu";
// import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import MDTypography from "components/MDTypography";
import ELInputStyle from "components/ELInputStyle";
// import ELDateLimits from "components/ELDateLimits";
import ELDefaultDaily from "components/ELDefaultDaily";
// import ELDownloadData from "components/ELDownloadData";

import Select from "react-select";

import PlotTrendExtended from "../trends/plotTrendExtended";

// import ELDateLimits from "../trends/getDateLimits";

// const customStyles = {
//   control: (base) => ({
//     ...base,
//     height: 35,
//     minHeight: 35,
//     fontSize: 12,
//     fontWeight: "bold",
//     borderRadius: "25px",
//     textAlign: "center",
//   }),
//   option: (styles) => ({
//     ...styles,
//     fontSize: 12,
//     textAlign: "center",
//     borderRadius: "25px",
//   }),
// };

function StringCurve() {
  const optionsInfo = {
    hAxis: {
      // showTextEvery: 3,
      format: "MMM/d/yy",
      gridlines: {
        count: 7,
      },
      textStyle: { fontSize: 10 },
    },
    vAxis: { title: "Current", min: 0 },
    // hAxis: { title: "Time" },
    legend: {
      alignment: "start",
      position: "top",
      textStyle: { color: "info", fontSize: 12 },
      maxLines: 4,
    },
    seriesType: "lines",

    // curveType: "function",
  };

  const chartInfo = {
    chartType: "ComboChart",
    height: "84vh",
    options: optionsInfo,
  };

  const [dataApi, setPlotAPI] = useState("");

  const Inverters = localStorage.getItem("INVNum");

  const options = [];
  for (let index = 1; index <= Inverters; index += 1) {
    const element = "INV-".concat(index);
    options.push({ value: element, label: element });
  }

  // const options = [
  //   { value: "INV-1", label: "INV-1" },
  //   { value: "INV-2", label: "INV-2" },
  //   { value: "INV-3", label: "INV-3" },
  //   { value: "INV-4", label: "INV-4" },
  //   { value: "INV-5", label: "INV-5" },
  //   { value: "INV-6", label: "INV-6" },
  // ];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const getMPPTAPI = (dateInfo) => {
    const baseurl = global.baseurl;
    const delimiter = "/";
    const actiontype = "/regularmaindata";
    const apiEndPoint = "/get_mbus_data";
    const deviceID = localStorage.getItem("devID");

    const trendAPI = baseurl.concat(
      deviceID,
      delimiter,
      selectedOption.value,
      actiontype,
      delimiter,
      dateInfo.startDate,
      delimiter,
      dateInfo.endDate,
      apiEndPoint
    );
    return trendAPI;
  };

  let refresh = false;

  const getDateLimits = (dateInfo) => {
    const temp = getMPPTAPI(dateInfo);
    refresh = !refresh;
    useEffect(() => {
      setPlotAPI(temp);
    }, [refresh]);
  };

  // let dataRefresh = false;
  // let dlData = "";
  // const getDownloadData = (rawData) => {
  //   dataRefresh = !dataRefresh;
  //   useEffect(() => {
  //     dlData = rawData;
  //   }, []);
  // };

  // const [isDownload, setDownload] = useState(false);
  // const setDownloadFile = () => {
  //   setDownload(true);
  // };

  return (
    <MDBox
      variant="gradient"
      coloredShadow="dark"
      borderRadius="10px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={0.5}
    >
      <Grid flex container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <MDBox textAlign="center" justifyContent="center" alignItems="center" pb={0.5}>
            <MDTypography variant="h6" color="black" fontSize="bold">
              String Monitoring
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox px={1}>
            <Grid
              flex
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              columns={16}
              // spacing={1}
            >
              <Grid item xs={13.75} md={5} lg={5}>
                <MDBox textAlign="center" justifyContent="center" alignItems="center" px={2}>
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    styles={ELInputStyle}
                  />
                </MDBox>
              </Grid>
              {/* <ELDateLimits getDateLimits={getDateLimits} /> */}
              <ELDefaultDaily getDateLimits={getDateLimits} />
              {/* <Grid item>
                <MDBox display="flex" justifyContent="right" alignItems="center">              
                  <MDButton
                    variant="gradient"
                    color="info"
                    circular
                    iconOnly
                    // onClick={() => setDownloadFile()}
                    onClick={handleOpenMenu}
                    sx={{ ml: 1 }}
                  >
                    <Icon fontSize="small">download</Icon>
                  </MDButton>
                  {renderMenu()}
                </MDBox>
              </Grid> */}
            </Grid>
          </MDBox>
        </Grid>

        <Grid item xs={12}>
          <MDBox width="100%" p={0.5}>
            {dataApi && <PlotTrendExtended url={dataApi} chartInfo={chartInfo} searchKey={3} />}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default StringCurve;
