/* eslint-disable prefer-destructuring */
import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ELMobileNavbar from "components/ELMobileNavbar";
import ELMobileView from "components/ELMobileView";
import ELSecuity from "components/ELSecurity";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import PrepWMSGraph from "./prepWMSGraph";
import PrepWMSReports from "./prepwmsReport";

function WeatherReport() {
  ELSecuity();
  const onMobile = ELMobileView();

  const currDevPath = localStorage.getItem("devLocation");
  if (!currDevPath)
    return (
      <DashboardLayout>
        {onMobile && <ELMobileNavbar />}
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <MDTypography>Select Device From Device Tree</MDTypography>
        </MDBox>
      </DashboardLayout>
    );

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      {onMobile && <ELMobileNavbar />}

      <MDBox
        flex
        justifyContent="center"
        alignItems="center"
        mt={-1}
        border={4}
        bgColor="white"
        borderColor="#ced4da"
        borderRadius="10px"
      >
        <MDBox
          flex
          align="center"
          borderRadius="25px"
          bgColor="info"
          variant="gradient"
          shadow="lg"
          mx="25%"
          py={0.5}
          alignItems="center"
          // width="50%"
          justifyContent="center"
          mb={1}
        >
          <MDTypography flex verticalAlign="center" variant="h6" color="white" fontWeight="regular">
            Weather Statistics
          </MDTypography>
        </MDBox>
        <Tabs backgroudorientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
          <Tab label="Trends" sx={{ borderBottom: 2 }} />
          <Tab label="Table" sx={{ borderBottom: 2 }} />
        </Tabs>
        {tabValue === 0 && <PrepWMSGraph />}
        {tabValue === 1 && <PrepWMSReports />}
      </MDBox>
    </DashboardLayout>
  );
}
export default WeatherReport;
