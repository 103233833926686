/* eslint-disable prefer-destructuring */
import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";

import ELInputStyle from "components/ELInputStyle";
// import ELDateLimits from "components/ELDateLimits";
import ELDefaultDaily from "components/ELDefaultDaily";

import Select from "react-select";

import GetMPPTAPI from "./getMPPTApi";

function PrepAPI({ getApiInfo }) {
  const Inverters = localStorage.getItem("INVNum");

  const options = [];
  for (let index = 1; index <= Inverters; index += 1) {
    const element = "INV-".concat(index);
    options.push({ id: index, value: element, label: element });
  }
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const [dataApi, setPlotAPI] = useState("");

  let refresh = false;

  const getDateLimits = (dateInfo) => {
    const temp = GetMPPTAPI(dateInfo, selectedOption.value);
    refresh = !refresh;
    useEffect(() => {
      setPlotAPI(temp);
    }, [refresh]);
    // }, [selectedOption, dateInfo.startDate, dateInfo.endDate]);
  };

  return (
    <MDBox bgColor="white" justifyContent="center" alignItems="center" px={2}>
      <Grid flex container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={3}>
          <MDBox textAlign="center" justifyContent="center" alignItems="center">
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              styles={ELInputStyle}
            />
          </MDBox>
        </Grid>
        <Grid item xs={3}>
          {/* <ELDateLimits getDateLimits={getDateLimits} /> */}
          <ELDefaultDaily getDateLimits={getDateLimits} />
        </Grid>
      </Grid>
      {getApiInfo(dataApi, selectedOption.id)}
    </MDBox>
  );
}

PrepAPI.defaultProps = {
  getApiInfo: "",
};

PrepAPI.propTypes = {
  getApiInfo: PropTypes.func,
};

export default PrepAPI;
