/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { RotatingLines, Bars } from "react-loader-spinner";

function LoadingSpin({ pattern }) {
  if (pattern === "graph")
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center">
        <Bars
          color="grey"
          ariaLabel="bars-loading"
          animationDuration="0.75"
          height="40"
          width="40"
          visible
        />
      </MDBox>
    );
  return (
    <MDBox display="flex" justifyContent="center" alignItems="center">
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="40"
        visible
      />
    </MDBox>
  );
}

LoadingSpin.defaultProps = {
  pattern: "",
};

LoadingSpin.propTypes = {
  pattern: PropTypes.string,
};

export default LoadingSpin;
