/* eslint-disable prefer-destructuring */
import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";

import PropTypes from "prop-types";
import { Chart } from "react-google-charts";
import MDBox from "components/MDBox";

// import ELDownloadData from "components/ELDownloadData";
import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

// import ShowReportTable from "./showReportTable"

import ShowReportTable from "layouts/reports/showReportTable";

function flattenObject(tableData) {
  const tableColumns = [{ field: "id", headerName: "ID" }];

  const tableRows = [];

  // console.log("flattenObject...", tableData[0]);

  if (tableData)
    if (tableData.length) {
      const myHead = tableData[0];
      // console.log("myHead", myHead);

      myHead.forEach((elm) => {
        const check = elm.replace("instantpower", "").replace("plant", "");

        tableColumns.push({
          field: check.toUpperCase(),
          headerName: check.toUpperCase(),
          flex: 1,
          minWidth: 120,
          // width: 120,
          align: "center",
          headerAlign: "center",
        });
      });

      // console.log("flattenObject...", tableData, myHead);

      tableData.forEach((elm, index) => {
        const rowEntry = {};
        rowEntry.id = index;
        elm.forEach((val, count) => {
          rowEntry[tableColumns[count + 1].headerName] = val;
        });
        if (index !== 0) tableRows.push(rowEntry);
      });
    }
  const tableHeader = tableColumns.slice(1, tableColumns.length);

  return { tableHeader, tableRows };
}

function PlotTrend({ url, chartInfo }) {
  if (!url) return null;

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const { data: graphData, loading, error } = useFetch(url);

  if (loading) return <LoadingSpin pattern="graph" />;
  if (error) {
    if (error.elmessage)
      return (
        <MDBox color="error" textAlign="center">
          {error.elmessage}
        </MDBox>
      );
    return (
      <MDBox color="error" textAlign="center">
        {error.message}
      </MDBox>
    );
  }

  if (graphData)
    if (graphData.length) {
      const series = chartInfo.options.series;

      // console.log("graphData ", graphData);
      const { tableHeader, tableRows } = flattenObject(graphData);

      // console.log("tableHeader, tableRows", tableHeader, tableRows);

      const seriesDisplay = [];
      const seriesColorBackup = [];

      // console.log("series : ", series);
      if (series !== undefined)
        Object.keys(series).forEach((elm, index) => {
          seriesColorBackup[index] = elm.color;
          seriesDisplay[index] = true;
        });

      const columns = [];
      const chartEvents = [
        {
          eventName: "select",
          callback({ chartWrapper }) {
            const elChart = chartWrapper.getChart();
            const Selected = elChart.getSelection();
            const dataTable = chartWrapper.getDataTable();

            if (!columns.length)
              for (let i = 0; i < dataTable.getNumberOfColumns(); i += 1) {
                columns.push(i);
              }
            if (Selected.length > 0) {
              // Legend Selected
              if (Selected[0].row === null && Selected[0].column > 0) {
                const col = Selected[0].column;
                // toggle selected series display
                seriesDisplay[col] = !seriesDisplay[col];
                // select series display color
                series[col - 1].color = seriesDisplay[col] ? seriesColorBackup[col] : "#CCCCCC";
              }
            }
            const chartArea = elChart.getChartLayoutInterface().getChartAreaBoundingBox();
            const vAxisTop = elChart.getChartLayoutInterface().getVAxisValue(chartArea.top);
            const vAxisBtm = elChart
              .getChartLayoutInterface()
              .getVAxisValue(chartArea.top + chartArea.height);

            chartWrapper.setOption("series", series);
            chartWrapper.setOption("vAxis.viewWindow.max", vAxisTop);
            chartWrapper.setOption("vAxis.viewWindow.min", vAxisBtm);

            chartWrapper.draw();
          },
        },
      ];

      return (
        <>
          {graphData.length > 1 ? (
            <>
              <Tabs
                backgroudorientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab label="Trends" sx={{ borderBottom: 2 }} />
                <Tab label="Table" sx={{ borderBottom: 2 }} />
              </Tabs>
              {tabValue === 0 && (
                <>
                  <Chart
                    chartType={chartInfo.chartType}
                    data={graphData}
                    options={chartInfo.options}
                    height={chartInfo.height}
                    // formatters={formatters}
                    chartEvents={chartEvents}
                    loader={<LoadingSpin pattern="graph" />}
                  />
                  {/* {graphData && <ELDownloadData rawData={graphData} />} */}
                </>
              )}
              {tabValue === 1 && (
                <MDBox flex>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                      <Chart
                        chartType={chartInfo.chartType}
                        data={graphData}
                        options={chartInfo.options}
                        height={chartInfo.height}
                        // formatters={formatters}
                        chartEvents={chartEvents}
                        loader={<LoadingSpin pattern="graph" />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ShowReportTable tableHeader={tableHeader} tableRowData={tableRows} />
                    </Grid>
                  </Grid>
                </MDBox>
              )}
            </>
          ) : (
            <p> No data for selected dates </p>
          )}
        </>
      );
    }
  return null;
}

PlotTrend.defaultProps = {
  url: "",
  chartInfo: "",
  // dummyData: "",
};

PlotTrend.propTypes = {
  url: PropTypes.string,
  chartInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  // dummyData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default PlotTrend;
