import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";

const ParseLiveData = (liveAPI) => {
  const LiveParsedData = [];

  // console.log("ParseLiveData...liveAPI", liveAPI);
  const { data: liveData, loading, error } = useFetch(liveAPI);

  // console.log("liveData, loading, error", liveData, loading, error);
  // "Device Code",
  const keyLabels = [
    "Total Active Power",
    "Active Power",
    "Today Generation",
    "Life Generation",
    "Average Current",
    "Average Voltage",
    "Net Import-Export",
    "Solar Radiation",
    // "Wind Direction",
    "Wind Speed",
    "Ambient Temp",
    "Module Temp",
  ];

  if (liveData)
    if (liveData.length > 0) {
      //   console.log("liveData, loading, error", liveData[0].sources, loading, error);
      const metaData = liveData[0].sources;
      Object.keys(metaData).forEach((entry) => {
        const entity = metaData[entry];
        const dummy = [];
        Object.keys(entity).forEach((dev) => {
          if (dev !== "data") {
            dummy[dev] = entity[dev];
            // console.log("entity entity[dev]", dev, entity[dev]);
          } else {
            const devData = entity[dev];
            Object.keys(devData).forEach((dataEntry) => {
              keyLabels.forEach((keyparam) => {
                if (keyparam === devData[dataEntry].label)
                  dummy[devData[dataEntry].label] = devData[dataEntry].value;
              });
              // if (keyLabels.includes(devData[dataEntry].label))
              // dummy[devData[dataEntry].label] = devData[dataEntry].value;
            });
          }
        });
        LiveParsedData.push(dummy);
      });
    }
  // console.log("LiveParsedData", LiveParsedData);
  return { LiveParsedData, loading, error };
};

export default ParseLiveData;
