import { useState, useEffect } from "react";
import Axios from "axios";

const useFetch = (url, refresh) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // useEffect(async () => {
  useEffect(() => {
    // eslint-disable-next-line
    // console.log("Remote API : ", url, refresh);
    setLoading(true);
    // await Axios.get(url)
    Axios.get(url)
      .then((response) => {
        // eslint-disable-next-line
        // console.log("API NON-NULL RESPONSE", response);
        setData(response.data);
        setError("");
      })
      .catch((err) => {
        // eslint-disable-next-line
        // console.log("API ERR RESPONSE", err);
        // setError(err);
        const errData = [
          {
            message: "",
            id: "",
            api: "",
            elmessage: "",
          },
        ];
        // console.log("err...", err);
        errData.message = err.message;
        if (err.response) {
          errData.error = err.response.data.error;
          errData.id = err.response.data.id;
          errData.api = err.response.data.api;
          errData.elmessage = err.response.data.message;
        }
        setError(errData);
        setData("");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, refresh]);

  // console.log("useFetch...loading, error", loading, error);

  return { data, loading, error };
};

export default useFetch;
