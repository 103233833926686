/* eslint-disable prefer-destructuring */

function getfullAPI(plotInfo) {
  //   console.log("getfullAPI...plotInfo", plotInfo);
  const baseurl = global.baseurl;
  const delimiter = "/";
  const apiEndPoint = "/plantdatatrend";

  const usr = localStorage.getItem("currentusr");
  const deviceID = localStorage.getItem("devID");

  // solarbe.rss-iiot.in/v1/userid/deviceid/action/startdate/enddate/timetype/plantdatatrend
  // solarbe.rss-iiot.in/v1/tester/zzz/planttotalgeneration/2023-07-5/2023-07-12/daily/plantdatatrend
  const trendAPI = baseurl.concat(
    usr,
    delimiter,
    deviceID,
    delimiter,
    plotInfo.actiontype,
    delimiter,
    plotInfo.startDate,
    delimiter,
    plotInfo.endDate,
    delimiter,
    plotInfo.timetype,
    apiEndPoint
  );
  return trendAPI;
}

const getPlotAPI = (dateInfo, plotInfo) => {
  const plotURLInfo = {};
  let trendAPI = "";
  Object.assign(plotURLInfo, plotInfo, dateInfo);
  trendAPI = getfullAPI(plotURLInfo);
  return trendAPI;
};

export default getPlotAPI;
