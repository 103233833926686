import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import moment from "moment-timezone";

const getkeyValue = (plantData, param) => {
  let val = "";
  Object.keys(plantData).forEach((el) => {
    if (el.includes(param)) {
      // console.log("getSubBlock... el", el, plantData[0][el]);
      val = plantData[el];
    }
  });
  // console.log("getSubBlock...", genBlock);
  return val;
};

function HeadingGrid({ plantData }) {
  const Co2Reduction = getkeyValue(plantData, "Co2 Reduction");
  const timeStamp = moment(plantData.Stationepcdate).format("DD-MMM-YYYY");
  const lastUpdate = moment(plantData.Captured_Time).format("DD-MMM-YYYY hh:mm a");

  // console.log("HeadingGrid...", plantData);

  const gridData = {
    Location: plantData.Station,
    Capacity: plantData.Stationcapacity,
    // User: localStorage.getItem("customer"),
    User: localStorage.getItem("devClientInfo"),
    EPC_Date: timeStamp,
    Grid: plantData.Grids,
    DGs: plantData.DGs,
    Inverters: plantData.Inverters,
    Emission: Co2Reduction,
    Revenue: "Rs. ".concat(plantData.Revenue, " /-"),
    Rate: "Rs. ".concat(plantData.Stationunitprice, " /- per Unit"),
  };

  const isGrid = "GRID : ";
  const entities = isGrid.concat(gridData.Grid, " , DGs :", gridData.DGs);
  const emissionLable = String(gridData.Emission).concat(" (ton)");
  // if (onMobile)
  //   return (
  //     <MDBox mb={2}>
  //       <DefaultInfoCard title="Plant" paramData={gridData} />
  //     </MDBox>
  //   );
  return (
    <MDBox py={2} mt={-2} mb={1}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={12} lg={2.4}>
          <MDBox>
            <ComplexStatisticsCard
              color="warning"
              icon="solar_power"
              title="Plant Capacity (kW)"
              count={gridData.Capacity}
              percentage={{
                color: "warning",
                amount: "Location :",
                label: gridData.Location,
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={12} lg={2.4}>
          <MDBox>
            <ComplexStatisticsCard
              color="info"
              icon="person"
              title="Client"
              count={gridData.User}
              percentage={{
                color: "secondary",
                amount: "EPC Date :",
                label: gridData.EPC_Date,
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={12} lg={2.4}>
          <MDBox>
            <ComplexStatisticsCard
              color="info"
              icon="miscellaneous_services"
              title="Entities"
              count={entities}
              percentage={{
                color: "secondary",
                amount: "Inverters :",
                label: gridData.Inverters,
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={12} lg={2.4}>
          <MDBox>
            <ComplexStatisticsCard
              color="info"
              icon="co2"
              title="Emission Reduction"
              count={emissionLable}
              percentage={{
                color: "secondary",
                amount: "Time :",
                label: lastUpdate,
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={12} lg={2.4}>
          <MDBox>
            <ComplexStatisticsCard
              color="warning"
              icon="currency_rupee" // import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
              title="Revenue"
              count={gridData.Revenue}
              percentage={{
                color: "warning",
                amount: "Rate :",
                label: gridData.Rate,
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

HeadingGrid.defaultProps = {
  plantData: null,
  // onMobile: false,
};

HeadingGrid.propTypes = {
  plantData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  // onMobile: PropTypes.bool,
};

export default HeadingGrid;
