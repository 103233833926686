import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import { Chart } from "react-google-charts";
// import testGif from "assets/images/solarenergy.gif";
import PropTypes from "prop-types";
// import moment from "moment-timezone";
import TitleInfo from "../TitleInfo";
import HorizontalSlider from "./HorizontalSlider";
import EnergyFlow from "./EnergyFlow";
import MeterInfo from "./HorizontalSlider/meterInfo";
// function PieChartData() {
//   const data = [
//     ["Energy", "Generation"],
//     ["Inv-1", 11],
//     ["Inv-2", 2],
//     ["Inv-3", 2],
//     ["Inv-4", 2],
//     ["Inv-5", 7],
//   ];

//   const options = {
//     title: "Instataneous Generation",
//     titleTextStyle: {
//       color: "info", // any HTML string color ('red', '#cc00cc')
//       fontSize: 16, // 12, 18 whatever you want (don't specify px)
//       bold: true, // true or false
//       italic: true, // true of false
//     },
//     legend: { position: "bottom", textStyle: { color: "warning", fontSize: 10 } },
//   };
//   return <Chart chartType="PieChart" data={data} options={options} width="100%" height="300px" />;
// }

// function BarChartGeneration() {
//   const data = [
//     ["Energy", "Load", { role: "style" }],
//     ["Solar", 40.5, "info"], // RGB value
//     ["Grid", 24.49, "info"], // English color name
//     ["DG", 59.3, "info"],
//   ];
//   const options = {
//     title: "Load",
//     titleTextStyle: {
//       align: "center",
//       color: "info", // any HTML string color ('red', '#cc00cc')
//       fontSize: 16, // 12, 18 whatever you want (don't specify px)
//       bold: true, // true or false
//       italic: true, // true of false
//     },
//     legend: { position: "bottom", textStyle: { color: "warning", fontSize: 10 } },
//   };

//   return (
//     <Chart chartType="ColumnChart" data={data} options={options} width="100%" height="300px" />
//   );
// }

// function displayParam(val, bgcolor, txtColor) {
//   return (
//     <Grid item key={val}>
//       <MDBox
//         flex
//         align="center"
//         key={val}
//         borderRadius="25px"
//         // border={2}
//         // borderColor="gold"
//         mt={0.25}
//         bgcolor={bgcolor}
//         // color="white"
//         variant="gradient"
//         shadow="lg"
//       >
//         <MDTypography
//           flex
//           variant="caption"
//           color={txtColor}
//           fontWeight="bold"
//           textTransform="capitalize"
//         >
//           {val}
//         </MDTypography>
//       </MDBox>
//     </Grid>
//   );
// }

// function PerformanceInfo() {
//   const DevInfo = {
//     // time: moment(Date()).format("DD-MMM-YYYY hh:mm a"),
//     PerformanceRatio: 0.3,
//     Irradation: 25,
//   };
//   return (
//     <MDBox mb={1}>
//       <Grid
//         flex
//         container
//         direction="row"
//         justifyContent="center"
//         alignItems="center"
//         spacing={1}
//         px={2}
//       >
//         <Grid item xs={6}>
//           {Object.keys(DevInfo).map((key) => displayParam(key, "warning", "dark"))}
//         </Grid>
//         <Grid item xs={6}>
//           {Object.values(DevInfo).map((val) => displayParam(val, "info", "dark"))}
//         </Grid>
//       </Grid>
//     </MDBox>
//   );
// }

const getSubBlock = (plantData) => {
  // console.log("HED...", plantData);

  const MeterData = [];

  const paramKey = ["Total Instantaneous Power", "Power Factor", "Frequency"];

  Object.keys(plantData).forEach((el) => {
    if (paramKey.includes(el)) {
      // console.log("Performance... el", el, plantData[el]);
      MeterData[el] = plantData[el];
    }
  });
  // console.log("getSubBlock...", MeterData);
  return { MeterData };
};

const getPowerBlock = (plantData) => {
  // console.log("getPowerBlock...", plantData);
  const powerData = [];

  Object.keys(plantData).forEach((el) => {
    if (el.includes("Instant") || el.includes("Captured_Time") || el.includes("Stationcapacity")) {
      // console.log("Performance... el", el, plantData[el]);
      powerData[el] = plantData[el];
    }
  });
  // console.log("getPowerBlock...", powerData);
  return { powerData };
};

function HeadingInfo({ CurrDeviceInfo, plantData }) {
  const { MeterData } = getSubBlock(plantData);

  const { powerData } = getPowerBlock(plantData);

  // console.log("HeadingInfo", MeterData);

  // const MeterData = {
  //   instaPower: 345.,
  //   powerFactor: 0.9,
  //   frequency: 48.89,
  // };

  return (
    <MDBox display="flex" justifyContent="space-between" mt={-1}>
      <Grid flex container direction="row" justifyContent="center" alignItems="stretch" spacing={1}>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox border={4} bgColor="#edfbef" borderColor="#adb5bd" borderRadius="12px">
            <TitleInfo CurrDeviceInfo={CurrDeviceInfo} />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox border={4} bgColor="#edfbef" borderColor="#adb5bd" borderRadius="12px">
            <EnergyFlow powerData={powerData} />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox border={4} bgColor="#edfbef" borderColor="#adb5bd" borderRadius="12px">
            <MDBox justifyContent="center" alignItems="center" px={2}>
              <HorizontalSlider />
            </MDBox>
            <MDBox justifyContent="center" alignItems="center" px={2} mt={2.5} pb={1}>
              <MeterInfo MeterData={MeterData} />
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

HeadingInfo.defaultProps = {
  CurrDeviceInfo: "",
  plantData: "",
};

HeadingInfo.propTypes = {
  CurrDeviceInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  plantData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
export default HeadingInfo;
