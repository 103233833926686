/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */

import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import ApiError from "layouts/dashboard/components/RemoteApi/apiError";
import PopulateReports from "./populateReport";

import "./tableFormat.css";

const getWMSReportDataApi = (wmsResourceName) => {
  // https://rss-iiot.in/solarbe/v1/RSSA1024/WMS-1/wms/2024-09-11/2024-09-12/get_solar_data_tabular
  // https://solarbe.rss-iiot.in/v1/iiot@rss/RSSA1024/WMS-1/wms/
  // const baseurl = global.newbaseurl;
  const baseurl = global.baseurl;
  const delimiter = "/";
  const apiEndPoint = "wms";
  // const usr = localStorage.getItem("currentusr");
  const deviceID = localStorage.getItem("devID");

  // console.log("getWMSReportDataApi ", baseurl);

  const wmsDataApi = baseurl.concat(
    // usr,
    // delimiter,
    deviceID,
    delimiter,
    wmsResourceName,
    delimiter,
    apiEndPoint,
    delimiter
  );

  // console.log("getWMSReportDataApi-wmsDataApi :  ", wmsDataApi);

  return wmsDataApi;
};

const getDeviceResourceApi = () => {
  // https://solarbe.rss-iiot.in/v1/iiot@rss/RSSA1024/get_user_resource_tree

  const baseurl = global.baseurl;
  const delimiter = "/";
  const apiEndPoint = "/get_user_resource_tree";

  const usr = localStorage.getItem("currentusr");
  const deviceID = localStorage.getItem("devID");

  const devResouceAPI = baseurl.concat(usr, delimiter, deviceID, delimiter, apiEndPoint);
  return devResouceAPI;
};

function PrepWMSReports() {
  // console.log("ReportTable...1 dataAPI", dataAPI);

  let wmsResourceName = null;
  let wmsReportAPI = null;

  const devResourceAPI = getDeviceResourceApi();

  const { data: wmsResource, loading: resLoading, error: resError } = useFetch(devResourceAPI);

  if (resLoading) return <LoadingSpin />;
  if (resError) return <ApiError errorDetail={resError} />;

  if (wmsResource)
    if (wmsResource.length > 0) {
      // console.log("wmsResource...", wmsResource);
      wmsResource.forEach((elment) => {
        elment.substation.forEach((dev) => {
          const devSource = dev.devices[0].sources;
          // console.log("Entry :", dev.devices);
          devSource.forEach((entry) => {
            if (entry.product === "SENSOR-WMS") wmsResourceName = entry.name;
          });
        });
      });

      if (wmsResourceName)
        if (wmsResourceName.length > 0) {
          wmsReportAPI = getWMSReportDataApi(wmsResourceName);
          return <PopulateReports baseAPI={wmsReportAPI} />;
        }
    }

  return null;
}

// PrepWMSReports.defaultProps = {
//   dataAPI: "",
// };

// PrepWMSReports.propTypes = {
//   dataAPI: PropTypes.string,
// };

export default PrepWMSReports;
