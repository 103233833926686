import { useState } from "react";
// import { Routes, Route, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import Axios from "axios";

// import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

function DiscardPendingRequest({ refreshManagepage }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [res, setResponse] = useState(null);
  const [show, setShow] = useState(false);

  const baseurl = "https://solarbe.rss-iiot.in/v1/";
  const delimiter = "/";
  const devID = localStorage.getItem("devID");
  const username = localStorage.getItem("currentusr");
  const endPoint = "/rsssolarv1/abortboxconfigurations";

  const urlAbort = baseurl.concat(username, delimiter, "null", delimiter, devID, endPoint);

  const abortUpdate = () => {
    setLoading(true);
    setShow(!show);
    Axios.get(urlAbort)
      .then((response) => {
        // eslint-disable-next-line
        console.log("API NON-NULL RESPONSE", response);
        setError("");
        setResponse(response.data);
      })
      .catch((err) => {
        // Handle Error Here
        // eslint-disable-next-line
        // console.log(err);
        setError(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshPage = () => {
    setShow(!show);
    refreshManagepage(true);
  };

  return (
    <>
      <MDButton variant="gradient" color="error" onClick={abortUpdate}>
        Discard Pending Request
      </MDButton>
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Configurtion Status"
        content={res !== null ? res : error}
        dateTime={loading ? "..." : "  "}
        open={show}
        close={refreshPage}
      />
    </>
  );
}

DiscardPendingRequest.defaultProps = {
  // confStatusInfo: "",
  refreshManagepage: "",
};

DiscardPendingRequest.propTypes = {
  // confStatusInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  refreshManagepage: PropTypes.func,
};

export default DiscardPendingRequest;
