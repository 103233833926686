import { useEffect, useState } from "react";
import breakpoints from "assets/theme/base/breakpoints";

const ELMobileView = () => {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function isMobileView() {
      if (window.innerWidth < breakpoints.values.sm) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }
    /** 
         The event listener that's calling the displayMobileNavbar function when 
         resizing the window.
        */
    window.addEventListener("resize", isMobileView);

    // Call the displayMobileNavbar function to set the state with the initial value.
    isMobileView();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", isMobileView);
  }, []);

  return mobileView;
};

export default ELMobileView;
