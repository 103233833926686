/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import ParseExtendedData from "../trends/parseExtendedData";

const PlotTabs = (graphData, searchKey) => {
  const chartData = ParseExtendedData(graphData, searchKey);
  return chartData;
};

export default PlotTabs;
