import { useEffect, useState } from "react";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// import ELDateLimits from "components/ELDateLimits";
import ELDefaultDaily from "components/ELDefaultDaily";

import PlotTrend from "../plotTrend";

import GetPlotAPI from "../getPlotAPI";

function PlantTotalPower() {
  // const handle = useFullScreenHandle();
  const plotInfo = {
    actiontype: "plantallpowercurve",
    timetype: "regular",
  };

  const optionsInfo = {
    // theme: "maximized",
    // backgroundColor: 'transparent',

    isStacked: "relative",
    // explorer: {
    //   axis: "horizontal",
    //   // keepInBounds: true,
    //   // maxZoomIn: 4.0,
    //   actions: ["dragToZoom", "rightClickToReset"],
    // },

    hAxis: {
      format: "MMM/d/yy",
      gridlines: {
        count: 7,
      },
      textStyle: { fontSize: 10 },
      // showTextEvery: 5,
    },
    // axisTitlesPosition: "in",
    vAxis: {
      title: "Power",
      viewWindow: {
        min: 0,
      },
    },
    // curveType: "function",
    // series: { 0: { color: "#fb8c00" } },
    // legend: { position: "bottom", textStyle: { color: "warning", fontSize: 10 } },

    legend: {
      alignment: "center",
      position: "top",
      textStyle: { fontSize: 12, bold: true },
      maxLines: 2,
    },

    pointSize: 4,
    seriesType: "lines",
    series: {
      0: { pointShape: "circle", labelInLegend: " Grid ", color: "#3366cc" },
      1: { pointShape: "triangle", labelInLegend: " DG ", color: "#dc3912" },
      2: { pointShape: "square", labelInLegend: " Inverter ", color: "#ff9900" },
      3: { pointShape: "diamond", labelInLegend: " Total  ", color: "#109618" },
    },
  };

  const chartInfo = {
    chartType: "ComboChart",
    height: "400px",
    options: optionsInfo,
  };

  const chartInfoFullScreen = {
    chartType: "ComboChart",
    height: window.innerHeight,
    width: "100%",
    options: optionsInfo,
  };

  let refresh = false;

  const [dataApi, setPlotAPI] = useState("");

  const getDateLimits = (dateInfo) => {
    const temp = GetPlotAPI(dateInfo, plotInfo);
    refresh = !refresh;
    useEffect(() => {
      setPlotAPI(temp);
    }, [refresh]);
  };

  // if (dataApi) console.log("dataApi...", dataApi);
  const handle = useFullScreenHandle();

  return (
    <FullScreen handle={handle}>
      <MDBox
        variant="gradient"
        // coloredShadow="info"
        borderRadius="10px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={0.5}
      >
        <Grid flex container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <MDBox textAlign="left" justifyContent="left" alignItems="center">
              <MDTypography variant="h6" color="black" fontSize="bold">
                Plant Total Power
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox display="flex" justifyContent="right" alignItems="center">
              <ELDefaultDaily getDateLimits={getDateLimits} />
              {handle.active ? (
                <MDButton variant="gradient" color="info" circular iconOnly onClick={handle.exit}>
                  <Icon fontSize="small">fullscreen_exit</Icon>
                </MDButton>
              ) : (
                <MDButton variant="gradient" color="info" circular iconOnly onClick={handle.enter}>
                  <Icon fontSize="small">fullscreen</Icon>
                </MDButton>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            {dataApi && (
              <MDBox
                flex
                width={handle.active ? window.innerWidth : "100%"}
                minHeight={handle.active && window.innerHeight}
                // p={0.5}
              >
                {handle.active ? (
                  <MDBox flex justifyContent="top" alignItems="center">
                    <MDTypography color="white">fs</MDTypography>
                    <PlotTrend url={dataApi} chartInfo={chartInfoFullScreen} />
                  </MDBox>
                ) : (
                  <PlotTrend url={dataApi} chartInfo={chartInfo} />
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </FullScreen>
  );
}

export default PlantTotalPower;
