/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { useState } from "react";

import Carousel from "react-material-ui-carousel";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

import ParseLiveData from "./parseLiveData";

function DetailList({ param, value }) {
  // console.log("DetailList", param, value);

  if (param === "Captured_Time") {
    value = moment(new Date(value)).format("DD-MMM-YY hh:mm a");
  }

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={0.4}
    >
      <MDBox>
        <MDTypography
          display="block"
          variant="caption"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {param}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {value}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

function Item({ ItemProps }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox px={2}>
        <MDBox component="ul" display="flex" flexDirection="column">
          {Object.entries(ItemProps).map(([key, value]) => (
            <DetailList key={key} param={key} value={value} />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

const getLiveAPI = () => {
  //   https://solarbe.rss-iiot.in/v1/userid/location/stationid/deviceid/null/mbus_live

  // example
  // https://solarbe.rss-iiot.in/v1/tester/UTTAR PRADESH/GREATER NOIDA/SUNGROW101/null/mbus_live

  const baseurl = global.baseurl;
  const delimiter = "/";
  const apiEndPoint = "/mbus_live";
  const filler = "/null";
  const usr = localStorage.getItem("currentusr");
  const location = localStorage.getItem("devLocation");
  const stationid = localStorage.getItem("devSubStation");
  const deviceID = localStorage.getItem("devID");

  const trendAPI = baseurl.concat(
    usr,
    delimiter,
    location,
    delimiter,
    stationid,
    delimiter,
    deviceID,
    filler,
    apiEndPoint
  );
  return trendAPI;
};

function HorizontalSlider() {
  const [index, setIndex] = useState(0);

  const handleChange = (cur) => {
    setIndex(cur);
  };

  const liveAPI = getLiveAPI();

  // console.log("EntityInfo...", liveAPI);

  const { LiveParsedData, loading, error } = ParseLiveData(liveAPI);

  // console.log("EntityInfo...", LiveParsedData);

  // const DevDetails = [
  //   {
  //     Name: "Entiety-1",
  //     // Make: "Make",
  //     // Ratings: "Rating",
  //     Status: "Offline",
  //     "Instantaneous Power": 100,
  //     "Generation (Today)": 200,
  //     "Generation (Total)": 300,
  //     "Peak KW": 450,
  //   },
  //   {
  //     Name: "Entiety-2",
  //     // Make: "Make-2",
  //     // Ratings: "Rating-2",
  //     Status: "Offline",
  //     "Instantaneous Power": 100,
  //     "Generation (Today)": 200,
  //     "Generation (Total)": 300,
  //     "Peak KW": 450,
  //   },
  //   {
  //     Name: "Entiety-3",
  //     // Make: "Make-3",
  //     // Ratings: "Rating-3",
  //     Status: "Offline",
  //     "Instantaneous Power": 100,
  //     "Generation (Today)": 200,
  //     "Generation (Total)": 300,
  //     "Peak KW": 450,
  //   },
  //   {
  //     Name: "Entiety-4",
  //     // Make: "Make-4",
  //     // Ratings: "Rating-4",
  //     Status: "Offline",
  //     "Instantaneous Power": 100,
  //     "Generation (Today)": 200,
  //     "Generation (Total)": 300,
  //     "Peak KW": 450,
  //   },
  //   {
  //     Name: "Entiety-5",
  //     // Make: "Make-5",
  //     // Ratings: "Rating-5",
  //     Status: "Offline",
  //     "Instantaneous Power": 100,
  //     "Generation (Today)": 200,
  //     "Generation (Total)": 300,
  //     "Peak KW": 450,
  //   },
  //   {
  //     Name: "Entiety-6",
  //     // Make: "Make-6",
  //     // Ratings: "Rating-6",
  //     Status: "Offline",
  //     "Instantaneous Power": 100,
  //     "Generation (Today)": 200,
  //     "Generation (Total)": 300,
  //     "Peak KW": 450,
  //   },
  //   {
  //     Name: "Entiety-7",
  //     // Make: "Make-7",
  //     // Ratings: "Rating-7",
  //     Status: "Offline",
  //     "Instantaneous Power": 100,
  //     "Generation (Today)": 200,
  //     "Generation (Total)": 300,
  //     "Peak KW": 450,
  //   },
  // ];

  return (
    <Card sx={{ bgcolor: "#edfbef", minHeight: "230px" }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" mb={1.25}>
        <MDTypography flex variant="h6" color="dark" fontWeight="bold" textTransform="capitalize">
          entity
        </MDTypography>
      </MDBox>
      <MDBox flex>
        {loading && <LoadingSpin />}
        {error && <p>Server Error: {error.message}</p>}
        <Carousel
          index={index}
          onChange={handleChange}
          interval={3000}
          animation="slide"
          indicators
          stopAutoPlayOnHover
          duration={1500}
          swipe
          width="200px"
          height="150px"
        >
          {LiveParsedData.map((item) => (
            <Item key={item} ItemProps={item} />
          ))}
        </Carousel>
      </MDBox>
    </Card>
  );
}

DetailList.defaultProps = {
  param: "",
  value: "",
};

// Typechecking props for the DetailList
DetailList.propTypes = {
  param: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Item.defaultProps = {
  ItemProps: "",
};

// Typechecking props for the DetailList
Item.propTypes = {
  ItemProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default HorizontalSlider;
