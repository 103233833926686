/* eslint-disable prefer-destructuring */

import { useState, useEffect } from "react";
import Select from "react-select";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ELMobileView from "components/ELMobileView";
import ELInputStyle from "components/ELInputStyle";
import ELDateLimits from "components/ELDateLimits";
import ELMobileNavbar from "components/ELMobileNavbar";
import ELSecuity from "components/ELSecurity";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PopulateReports from "./populateReport";

// import PrepReportTable from "./prepReportTable";

function Reports() {
  ELSecuity();
  const onMobile = ELMobileView();
  const currDevPath = localStorage.getItem("devLocation");
  if (!currDevPath)
    return (
      <DashboardLayout>
        {onMobile && <ELMobileNavbar />}
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <MDTypography>Select Device From Device Tree</MDTypography>
        </MDBox>
      </DashboardLayout>
    );

  const GridNum = localStorage.getItem("GridNum");
  const DGsNum = localStorage.getItem("DGsNum");
  const INVNum = localStorage.getItem("INVNum");

  const options = [];
  for (let index = 1; index <= INVNum; index += 1) {
    const element = "INV-".concat(index);
    options.push({ value: element, label: element });
  }
  for (let index = 1; index <= GridNum; index += 1) {
    const element = "GRID-".concat(index);
    options.push({ value: element, label: element });
  }
  for (let index = 1; index <= DGsNum; index += 1) {
    const element = "DG-".concat(index);
    options.push({ value: element, label: element });
  }

  const [selectedOption, setSelectedOption] = useState(options[0]);

  // console.log("{selectedOption}", selectedOption);

  const [dataApi, setPlotAPI] = useState("");

  //  https://rss-iiot.in/elzebe/v1/RSSA1015/INV-2/regularmaindata/2024-07-29/2024-09-05/get_mbus_data_tabular

  // const getMPPTAPI = (dateInfo) => {
  //   const baseurl = global.baseurl;
  //   const delimiter = "/";
  //   const actiontype = "/regularmaindata";
  //   const apiEndPoint = "/get_mbus_data";
  //   const deviceID = localStorage.getItem("devID");

  //   const trendAPI = baseurl.concat(
  //     deviceID,
  //     delimiter,
  //     selectedOption.value,
  //     actiontype,
  //     delimiter,
  //     dateInfo.startDate,
  //     delimiter,
  //     dateInfo.endDate,
  //     apiEndPoint
  //   );
  //   return trendAPI;
  // };

  const getReportAPI = (dateInfo) => {
    const baseurl = global.baseurl;
    const delimiter = "/";
    const actiontype = "/regularmaindata";
    const apiEndPoint = "/get_mbus_data_tabular";
    const deviceID = localStorage.getItem("devID");
    // const deviceID = "RSSA1015";

    const trendAPI = baseurl.concat(
      deviceID,
      delimiter,
      selectedOption.value,
      actiontype,
      delimiter,
      dateInfo.startDate,
      delimiter,
      dateInfo.endDate,
      apiEndPoint
    );
    return trendAPI;
  };
  let refresh = false;

  const getDateLimits = (dateInfo) => {
    // const temp = getMPPTAPI(dateInfo);
    const temp = getReportAPI(dateInfo);
    refresh = !refresh;
    useEffect(() => {
      setPlotAPI(temp);
    }, [refresh]);
  };

  return (
    <DashboardLayout>
      {onMobile && <ELMobileNavbar />}
      <MDBox mb={onMobile && -2} />
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgColor="white"
        border={1}
        px={0.5}
        borderRadius="12px"
      >
        <Grid
          container
          direction="row"
          justifyContent={onMobile ? "center" : "space-between"}
          alignItems="center"
        >
          <Grid item xs={12} md={4} lg={4}>
            <MDBox display="flex" alignItems="center" textAlign="center" lineHeight={1.25}>
              <MDBox minWidth="25%">
                <MDTypography
                  variant="caption"
                  color="black"
                  fontWeight="bold"
                  mx={1}
                  textAlign="center"
                >
                  Select Entity
                </MDTypography>
              </MDBox>
              <MDBox display flex minWidth="50%">
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  styles={ELInputStyle}
                />
              </MDBox>
            </MDBox>
          </Grid>
          {/* <Grid item xs={9} md={2} lg={2} m={0.5}>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              styles={ELInputStyle}
            />
          </Grid> */}
          <Grid item xs={9} md={3} lg={3} />
          <ELDateLimits getDateLimits={getDateLimits} />
        </Grid>
      </MDBox>
      <MDBox
        flex
        align="center"
        bgColor="white"
        // bgColor="info"
        // variant="gradient"
        shadow="lg"
        opacity={1}
        borderRadius="10px"
        py={4}
      >
        {dataApi && <PopulateReports dataAPI={dataApi} />}
      </MDBox>
    </DashboardLayout>
  );
}

// function Reports() {
//   ELSecuity();
//   const onMobile = ELMobileView();
//   const currDevPath = localStorage.getItem("devLocation");
//   if (!currDevPath)
//     return (
//       <DashboardLayout>
//         {onMobile && <ELMobileNavbar />}
//         <MDBox display="flex" justifyContent="center" alignItems="center">
//           <MDTypography>Select Device From Device Tree</MDTypography>
//         </MDBox>
//       </DashboardLayout>
//     );

//   const GridNum = localStorage.getItem("GridNum");
//   const DGsNum = localStorage.getItem("DGsNum");
//   const INVNum = localStorage.getItem("INVNum");

//   const options = [];
//   for (let index = 1; index <= INVNum; index += 1) {
//     const element = "INV-".concat(index);
//     options.push({ value: element, label: element });
//   }
//   for (let index = 1; index <= GridNum; index += 1) {
//     const element = "GRID-".concat(index);
//     options.push({ value: element, label: element });
//   }
//   for (let index = 1; index <= DGsNum; index += 1) {
//     const element = "DG-".concat(index);
//     options.push({ value: element, label: element });
//   }

//   const [selectedOption, setSelectedOption] = useState(options[0]);

//   // console.log("{selectedOption}", selectedOption);

//   const [dataApi, setPlotAPI] = useState("");

//   const getMPPTAPI = (dateInfo) => {
//     const baseurl = global.baseurl;
//     const delimiter = "/";
//     const actiontype = "/regularmaindata";
//     const apiEndPoint = "/get_mbus_data";
//     const deviceID = localStorage.getItem("devID");

//     const trendAPI = baseurl.concat(
//       deviceID,
//       delimiter,
//       selectedOption.value,
//       actiontype,
//       delimiter,
//       dateInfo.startDate,
//       delimiter,
//       dateInfo.endDate,
//       apiEndPoint
//     );
//     return trendAPI;
//   };

//   let refresh = false;

//   const getDateLimits = (dateInfo) => {
//     const temp = getMPPTAPI(dateInfo);
//     refresh = !refresh;
//     useEffect(() => {
//       setPlotAPI(temp);
//     }, [refresh]);
//   };

//   return (
//     <DashboardLayout>
//       {onMobile && <ELMobileNavbar />}
//       <MDBox mb={onMobile && -2} />
//       <MDBox
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         bgColor="white"
//         border={1}
//         // p={1}
//         // m={1}
//         borderRadius="12px"
//       >
//         <Grid
//           container
//           direction="row"
//           justifyContent={onMobile ? "center" : "space-between"}
//           alignItems="center"
//         >
//           <Grid item xs={12} md={4} lg={4}>
//             <MDBox textAlign="center" lineHeight={1.25}>
//               <MDTypography variant="caption" color="black" fontWeight="bold" mx={1}>
//                 Select Entity
//               </MDTypography>
//             </MDBox>
//           </Grid>
//           <Grid item xs={9} md={4} lg={4} m={0.5}>
//             <Select
//               defaultValue={selectedOption}
//               onChange={setSelectedOption}
//               options={options}
//               styles={ELInputStyle}
//             />
//           </Grid>
//           <ELDateLimits getDateLimits={getDateLimits} />
//         </Grid>
//       </MDBox>
//       <MDBox
//         flex
//         align="center"
//         color="white"
//         bgColor="grey"
//         variant="gradient"
//         shadow="lg"
//         opacity={1}
//         borderRadius="10px"
//         // mx={2}
//       >
//         {dataApi && <PrepReportTable dataAPI={dataApi} />}
//       </MDBox>
//     </DashboardLayout>
//   );
// }

export default Reports;
