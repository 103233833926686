// import { useEffect, useState } from "react";
// import { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import Divider from "@mui/material/Divider";

import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
// import ApiError from "layouts/dashboard/components/RemoteApi/apiError";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import PlotTabs from "./plotTabs";
import PlotMPPT from "./plotMPPT";
import PlotMPPTDual from "./plotMPPTDual";

function PrepData({ url, option, param }) {
  if (!url) return null;
  let dcCount = 0;
  // const [refresh, setRefresh] = useState(false);

  // const optionsInfo = {
  //   hAxis: {
  //     format: "MMM/d/yy",
  //     gridlines: {
  //       count: 7,
  //     },
  //     textStyle: { fontSize: 10, bold: true },
  //   },
  //   vAxis: {
  //     title: "DC (amp)",
  //     min: 0,
  //   },
  //   legend: {
  //     alignment: "left",
  //     position: "top",
  //     textStyle: { color: "info", fontSize: 12 },
  //     maxLines: 4,
  //   },
  //   seriesType: "lines",
  //   // curveType: "function",
  //   width: "98%",
  // };

  // const chartInfo = {
  //   chartType: "ComboChart",
  //   height: "400px",
  //   options: optionsInfo,
  // };

  // console.log(" PrepData url", url, option);

  // const testURL =
  //   "https://solarbe.rss-iiot.in/v1/SUNGROW100/INV-1/regularmaindata/2023-07-14/2023-07-16/get_mbus_data";

  // console.log(" PrepData  URL", url);

  const { data, loading, error } = useFetch(url);

  let myErr = "";
  if (error) {
    if (error) {
      if (error.elmessage) myErr = error.elmessage;
      else myErr = error.message;
    }
    return (
      <MDBox color="error" textAlign="center" textTransform="capitalize">
        {myErr}
      </MDBox>
    );
  }

  if (loading) return <LoadingSpin pattern="graph" />;

  if (data)
    if (data.length) {
      // console.log("data,  data.length", data[0], data.length);
      dcCount = 0;
      Object.keys(data[0]).forEach((key) => {
        if (String(key).includes("MPPT Current ")) {
          dcCount += 1;
        }
      });

      const graphData = PlotTabs(data, param);

      return (
        <MDBox flex bgColor="white">
          {data && <PlotMPPT graphData={graphData} />}
          <Divider />
          {option && <PlotMPPTDual graphData={data} invNum={option} dcCount={dcCount} />}
        </MDBox>
      );
    }
  return null;
}

PrepData.defaultProps = {
  url: "",
  option: "",
  param: "",
};

PrepData.propTypes = {
  url: PropTypes.string,
  option: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  param: PropTypes.number,
};

export default PrepData;
